import { defineMessage, MessageDescriptor } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/fr-ca';

import englishMessages from '@assets/locales/en-CA.json';
import frenchMessages from '@assets/locales/fr-CA.json';

export interface SupportedLocale {
  code: string,
  nameDescriptor: MessageDescriptor,
  messages?: Record<string, string>,
}

export interface I18nMessages {
  [locale: string]: {
    [id: string]: string;
  };
}

export const allSupportedLocales: SupportedLocale[] = [
  { code: 'en-CA', nameDescriptor: defineMessage({ id: 'English' }), messages: englishMessages },
  { code: 'fr-CA', nameDescriptor: defineMessage({ id: 'French' }), messages: frenchMessages },
];

export const defaultLocale = allSupportedLocales[1];

dayjs.locale(defaultLocale.code);