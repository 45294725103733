
import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { Add } from '@mui/icons-material';
import { Stack, Tab, Tabs } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { AddFormDialog } from '../components/AddForm/AddFormDialog';
import { FilledFormsView } from '../components/FilledFormsView';
import { FormsLimitedAccessView } from '../components/FormsLimitedAccessView';
import { FormsManagerView } from '../components/FormsManagerView';

export const FormsHomePage = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<'all' | 'filledForms'>(pathname.indexOf('filledForms') >= 0 ? 'filledForms' : 'all');

  const { isMobile } = useResponsive();

  const [isAddingForm, setIsAddingForm] = useState(false);
  const { projectId, canManageForms, isArchived } = useCurrentProject();

  useEffect(() => {
    switch (selectedTab) {
      case 'all':
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/forms`);
        break;
      case 'filledForms':
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/forms/filledForms`);
        break;
    }

  }, [navigate, projectId, selectedTab]);

  const tabs = [
    {
      value: 'all',
      displayString: formatMessage({ id: 'Forms' })
    },
    {
      value: 'filledForms',
      displayString: formatMessage({ id: 'Filled forms' })
    }
  ];

  return (
    <PageContainer
      title={formatMessage({ id: 'Forms' })}
      action={!isArchived && canManageForms && (
        <DisableableTooltip disabled={!isMobile} disableInteractive arrow enterTouchDelay={0} title={formatMessage({ id: 'Forms cannot be edited on mobile.' })}>
          <ResponsiveButton disabled={isMobile} icon={<Add />} variant='contained' onClick={() => setIsAddingForm(true)}>
            {formatMessage({ id: 'Add form' })}
          </ResponsiveButton>
        </DisableableTooltip>
      )}
    >
      <Stack gap={2}>
        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          {tabs.map((t, i) => (
            <Tab key={i} label={t.displayString} value={t.value} />
          ))}
        </Tabs>

        {selectedTab === 'all' && canManageForms && <FormsManagerView />}
        {selectedTab === 'all' && !canManageForms && <FormsLimitedAccessView />}

        {selectedTab === 'filledForms' &&
          <FilledFormsView />
        }

      </Stack>

      <AddFormDialog
        open={isAddingForm}
        onClose={() => setIsAddingForm(false)} />
    </PageContainer>
  );
};