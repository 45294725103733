import { DataTable } from '@components/DataTable';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import { DataGridProProps, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { FolderType, useProjectFoldersQuery } from 'gql/index';
import { useCallback, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FolderImportFormValues } from '../types';
import { treeSelectionUpdated } from '../utils';
import { FolderTreeGroupingCell } from './FolderTreeGroupingCell';

interface Props {
  disabled?: boolean;
}

export const FolderImportFoldersPicker: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<FolderImportFormValues>();

  const projectToImportFromId = useWatch({ control, name: 'projectToImportFromId' });
  const selectedFolderIds = useWatch({ control, name: 'folderIds' });

  const { data: foldersData, isFetching: isFetchingFolders } = useProjectFoldersQuery({
    projectId: projectToImportFromId ?? 0
  }, {
    select: d => d.projectFolders,
    enabled: !!projectToImportFromId
  });

  const folders = useMemo(() => {
    let folders = foldersData?.filter(f => f.folderType != FolderType.Root);

    if (disabled) {
      folders = folders?.filter(folder => selectedFolderIds.includes(folder.id));
    }

    return folders;
  }, [disabled, foldersData, selectedFolderIds]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 1,
    renderCell: params => <FolderTreeGroupingCell {...params} />
  };

  // Has to be memoized due to MUI bug: https://github.com/mui/mui-x/issues/7771#issuecomment-1920224215
  const getTreeDataPath = useCallback((row: NonNullable<typeof folders>[number]) => row.path?.split('/').slice(1).filter(Boolean) ?? [], []);

  const getTreeSelectionUpdate = useCallback((row: GridRowSelectionModel, field: ControllerRenderProps<FolderImportFormValues, 'folderIds'>) => treeSelectionUpdated(row as number[], field.value as number[], folders?.map(f => ({ id: f.id, hierarchyPath: f.path })) ?? [], field.onChange), [folders]);

  return (
    <Controller
      control={control}
      name='folderIds'
      rules={{ required: formatMessage({ id: 'At least one folder must be selected' }) }}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <Stack sx={{ outline: error ? t => `1px solid ${t.palette.error.main}` : undefined }}>
            <DataTable
              noDataMessage={formatMessage({ id: 'This project has no folders.' })}
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              isGroupExpandedByDefault={() => !!disabled}

              rows={folders ?? []}
              // The groupingColDef contains the name
              columns={[]}

              loading={isFetchingFolders}
              checkboxSelection={!disabled}
              rowSelectionModel={field.value}
              onRowSelectionModelChange={folderIds => getTreeSelectionUpdate(folderIds, field)}
            />
          </Stack>

          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};