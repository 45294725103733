import { FileIcon } from '@components/Icons/FileIcon';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Draw } from '@mui/icons-material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { WorkflowActionStatus, WorkflowVariableValueTypes, useFormTemplateQuery, useProjectFormSubmissionQuery } from 'gql/index';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ActionAccordion } from '../../WorflowInstance/Actions/ActionAccordion/ActionAccordion';
import { CompletedActionContentItemLink } from '../../WorflowInstance/Actions/ActionAccordion/CompletedActionContentItemLink';
import { ActionAccordionProps } from '../../WorflowInstance/Actions/Types';

export const FillFormActionExecution: React.FC<ActionAccordionProps> = (props) => {
  const { formatMessage } = useIntl();
  const { action, workflowId } = props;
  const { projectId } = useCurrentProject();
  const navigate = useNavigate();

  const templateId = useMemo(() => {
    return Number(action.fieldValues.find(f => f.value?.variableValueType === WorkflowVariableValueTypes.FormTemplate)?.value?.serializedValue);
  }, [action.fieldValues]);
  const { data: formTemplate } = useFormTemplateQuery({ id: templateId }, { select: p => p.formTemplate, meta: { nullable: true } });


  const submissionId = useMemo(() => Number(action.fieldValues.find(v => v.value?.variableValueType === WorkflowVariableValueTypes.FormSubmission)?.value?.serializedValue), [action.fieldValues]);
  const { data: submission } = useProjectFormSubmissionQuery({ submissionId }, {
    select: p => p.projectFormSubmission,
    enabled: !!submissionId,
    meta: { nullable: true }
  });

  const submissionFormUrl = `/${getTenantIdentifier()}/projects/${projectId}/workflow/${workflowId}/action/${action.id}/${submissionId}`;
  const handleOnClick = () => navigate(submissionFormUrl);

  return (
    <ActionAccordion
      action={action}
      subtitle={formTemplate?.formDefinition?.name}
      submitAction={{
        buttonIcon: <Draw />,
        buttonText: formatMessage({ id: 'Fill form' }),
        onClick: handleOnClick
      }}
    >
      {action.status === WorkflowActionStatus.Completed &&
        <CompletedActionContentItemLink
          ItemIcon={<FileIcon fileIconType='InternalForm' />}
          ItemName={submission?.formDefinition?.name ?? ''}
          linkPath={submissionFormUrl}
          titleText={formatMessage({ id: 'Form filled' })}
        />
      }
    </ActionAccordion>
  );
};