import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { useGetMe } from '@utils/useGetMe';
import { AllowedRespondents, ApplicationUser, FormSettings, FormStatus, FormType, ProjectForm, ProjectTaskStatus, ProjectTaskType, useProjectTasksQuery } from 'gql/index';

export const useFormSecurity = (projectForm?: null | Pick<ProjectForm, 'id' | 'status'> & {
  'formSettings': Pick<FormSettings, 'formType' | 'allowedRespondents'> &
  { 'allowedUsersRespondents': Pick<ApplicationUser, 'id'>[] }
  & { 'allowedSecurityGroupsRespondents': { 'members': Pick<ApplicationUser, 'id'>[] }[] };
}) => {
  const { isArchived, projectId } = useCurrentProject();
  const { me } = useGetMe();

  const { data: tasks } = useProjectTasksQuery({
    projectId, filter: {
      and: [
        { type: { eq: ProjectTaskType.FillForm } },
        { status: { neq: ProjectTaskStatus.Done } }
      ]

    }
  }, { select: d => d.projectTasks, staleTime: 60000 });

  const isAllowedResponder = (projectForm?.formSettings.allowedRespondents === AllowedRespondents.AllProjectMembers)
    || projectForm?.formSettings?.allowedUsersRespondents?.some(u => u.id === me?.id)
    || projectForm?.formSettings?.allowedSecurityGroupsRespondents?.some(g => g.members.some(u => u.id === me?.id));

  const canRequestSelfServeFormSubmission = !isArchived
    && projectForm?.formSettings.formType === FormType.SelfServe
    && projectForm?.status === FormStatus.Published
    && isAllowedResponder;

  const canRequestCollectInformationFormSubmission = !isArchived
    && projectForm?.formSettings.formType === FormType.CollectResponse
    && tasks?.some(t => t.assignees.some(a => a.id === me?.id));


  const canRequestFormSubmission = canRequestSelfServeFormSubmission
    || canRequestCollectInformationFormSubmission;

  return { canRequestFormSubmission };
};