import { UploadManagerContext } from '@components/FileUpload/UploadManagerContext';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FormControl, FormHelperText, FormLabel, Link, List, Stack, Typography, useTheme } from '@mui/material';
import { useContextSafe } from '@utils/useContextSafe';
import React, { useContext, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { UploadZoneRoot } from '../../../../../../components/FileUpload/UploadZoneRoot';
import { UploadList } from '../../../../../documents/components/UploadList';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { FormFillerValues } from '../../types';
import { FieldFillerProps } from '../FieldFiller';
import { useFieldPresenceUpdateEvents } from '../useFieldPresenceUpdateEvents';
import { FileAttachmentListItem } from './FileAttachmentListItem';
import { useFormFieldUploadDropzone } from './useFormFieldUploadDropzone';

export const FileFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { control } = useFormContext<FormFillerValues>();

  const { submissionId } = useContextSafe(FormFillerContext);

  const { formatMessage } = useIntl();

  const { palette } = useTheme();

  const { fields: files, ...filesField } = useFieldArray({ control, name: `values.${index}.files`, keyName: 'uniqueId' });

  const uploadBatchId = `formSubmissionId-${submissionId}-field-${formField.id}`;

  const { dropZone: { getInputProps, getRootProps, isDragActive } } = useFormFieldUploadDropzone({
    formSubmissionId: submissionId,
    fieldId: formField.id,
    disabled: disabled ?? false,
    allowMultipleFiles: formField.isMultiselect,
    uploadBatchId,
    onFileUploaded: (attachment) => {
      filesField.append({
        id: attachment.id,
        fileName: attachment.fileName,
        storageUrl: attachment.storageUrl
      });
    }
  });

  const updatePresenceEvents = useFieldPresenceUpdateEvents(formField.id);
  const { uploads: allUploads } = useContext(UploadManagerContext);
  const uploads = useMemo(() => allUploads.filter(p => p.batchId === uploadBatchId), [uploadBatchId, allUploads]);

  return (
    <Controller
      key={formField.id}
      control={control}
      name={`values.${index}.files`}
      rules={{
        validate: (formAttachments) => {
          if (formField.isRequired && formAttachments.length === 0)
            return formatMessage({ id: 'This field is required' });
          else if (!formField.isMultiselect && formAttachments.length > 1) {
            return formatMessage({ id: 'Only a single file is allowed.' });
          } else {
            return true;
          }
        }

      }}
      render={({ fieldState: { error } }) => (
        <FormControl {...updatePresenceEvents} error={!!error}>
          <FormLabel>{formField.name}</FormLabel>

          {uploads.length > 0 && <UploadList uploadsBatchId={uploadBatchId} />}

          {files.length > 0 &&
            <List disablePadding>
              {files.map((formAttachment, index) => (
                <FileAttachmentListItem
                  key={formAttachment.id}
                  fieldId={formField.id}
                  formAttachment={formAttachment}
                  canDelete={!disabled}
                  onDeleted={() => filesField.remove(index)}
                />
              ))}
            </List>
          }

          {!disabled && (formField.isMultiselect || files.length == 0) && (
            <UploadZoneRoot dropZoneRootProps={getRootProps()} error={!!error} isDragActive={isDragActive}>
              <UploadFileIcon color='primary' />
              <input {...getInputProps()} />
              <Stack alignItems='center' gap={1}>
                <Typography variant='body1'>
                  <Link href='#'>
                    {formatMessage({ id: 'Click to upload' })}
                  </Link>
                  {' '}{formatMessage({ id: 'or drag and drop' })}
                </Typography>
                <Typography variant='body1' color={palette.grey[600]}>{formatMessage({ id: '(10MB max)' })}</Typography>
              </Stack>
            </UploadZoneRoot>
          )}

          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );
};