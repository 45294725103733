import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeIcon from '@mui/icons-material/Mode';
import SendFilled from '@mui/icons-material/Send';
import { Button, Chip, Divider, Stack, Typography } from '@mui/material';
import React, { ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';
import { BuiltInRoles, RoleAssignmentFragmentFragment } from '../../../../gql';
import { userRoleMessages } from '../../../application/messages';
import { RoleAssignmentListItem } from '../Permissions/RoleAssignmentListItem';
import { PermissionsModalContext } from './PermissionsModal';

interface Props {
  roleAssignment: RoleAssignmentFragmentFragment;
}

const ResourceInfo: React.FC<Props> = ({ roleAssignment }) => {
  const { formatMessage } = useIntl();

  const taskAccessHeader = (child: ReactNode) => (
    <Stack gap={2}>
      <Typography fontWeight={500}>{formatMessage({ id: 'Access was granted as part of a request' })}</Typography>

      {child}
    </Stack>
  );

  if (roleAssignment.isFromSignDocumentTask) {
    return taskAccessHeader(
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} ml={2}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <ModeIcon />
          <Typography>{formatMessage({ id: 'Signatory' })}</Typography>
        </Stack>
        <Chip variant='outlined' color='info' label={formatMessage(userRoleMessages[BuiltInRoles.Reader])} />
      </Stack>
    );
  } else if (roleAssignment.isFromFillFormTask) {
    return taskAccessHeader(
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} ml={2}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <ModeIcon />
          <Typography>{formatMessage({ id: 'Form assignee' })}</Typography>
        </Stack>
        <Chip variant='outlined' color='info' label={formatMessage(userRoleMessages[BuiltInRoles.Reader])} />
      </Stack>
    );
  }

  const inheritedAccessHeader = (child: ReactNode) => (
    <Stack gap={2}>
      <Typography fontWeight={500}>{formatMessage({ id: 'Inherited access' })}</Typography>

      {child}
    </Stack>
  );

  if (roleAssignment.inheritedFromFolder?.name != null) {
    return inheritedAccessHeader(
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} ml={2}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <FontAwesomeIcon icon={faFolder} style={{ marginRight: 8 }} color='orange' />
          <Typography>{roleAssignment.inheritedFromFolder.name}</Typography>
        </Stack>
        <Chip variant='outlined' color='info' label={roleAssignment.applicationRole && formatMessage(userRoleMessages[roleAssignment.applicationRole.builtInRole])} />
      </Stack>
    );
  } else if (roleAssignment.isInherited) {
    return inheritedAccessHeader(
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} ml={2}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <FontAwesomeIcon icon={faFolder} color='orange' />
          <Typography>{formatMessage({ id: 'Project' })}</Typography>
        </Stack>
        <Chip variant='outlined' color='info' label={formatMessage(userRoleMessages[BuiltInRoles.Reader])} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Typography fontWeight={600}>{formatMessage({ id: 'Direct access' })}</Typography>

      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} ml={2}>
        <Typography>{formatMessage({ id: 'The following permission was granted explicitely' })}</Typography>

        <Chip variant='outlined' color='info' label={formatMessage(userRoleMessages[BuiltInRoles.Reader])} />
      </Stack>
    </Stack>
  );
};

export const AccessSummaryView: React.FC<Props> = ({ roleAssignment }) => {
  const { formatMessage } = useIntl();

  const { goToManageAccess, goToSendNotification } = useContext(PermissionsModalContext);

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h5'>{formatMessage({ id: 'Access Summary' })}</Typography>
      </Stack>

      <Stack direction={'row'} spacing={2} mb={2} justifyContent={'left'} alignItems={'center'} onClick={() => goToManageAccess()} sx={{ cursor: 'pointer' }}>
        <ArrowBackIcon />
        <Typography>{formatMessage({ id: 'Manage Access' })}</Typography>
      </Stack>

      <Divider />

      <RoleAssignmentListItem
        key={roleAssignment.id}

        roleAssignment={roleAssignment}
        secondaryAction={<Button onClick={() => goToSendNotification(roleAssignment)} endIcon={<SendFilled />}>{formatMessage({ id: 'Send notification' })} </Button >}
      />

      <Divider />

      <ResourceInfo roleAssignment={roleAssignment} />
    </Stack>
  );
};