import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { SavingStatusContextProvider } from '@components/Editor/TopBar/SavingStatusContext';
import { TemplateTitle } from '@components/Editor/TopBar/TemplateTitle';
import { CircularProgress, Stack } from '@mui/material';
import { useFormTemplateForEditorQuery } from 'gql/index';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormEditor } from '../components/FormEditor/FormEditor';
import { FormEditorPreviewDrawer } from '../components/FormEditorPreviewDrawer';
import { FormTemplateActions } from '../components/FormTemplateActions';

export const EditFormTemplatePage: React.FC = () => {
  const { templateId } = useParams();

  const { data: template, isFetching } = useFormTemplateForEditorQuery({ id: Number(templateId) || 0 }, {
    cacheTime: 0,
    select: d => d.formTemplate
  });

  const [isPreviewing, setPreviewing] = React.useState(false);

  if (!template) {
    return (
      <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <SavingStatusContextProvider isEditorLoading={isFetching}>
      <EditorTopBar
        isLoading={isFetching}
        title={<TemplateTitle title={template?.formDefinition?.name} />}
        topBarActions={template ? (
          <FormTemplateActions template={template} onPreviewClick={() => setPreviewing(true)} />
        ) : undefined}
      />

      <FormEditor
        formContent={template?.formDefinition}
      />

      <FormEditorPreviewDrawer
        open={isPreviewing}
        onClose={() => setPreviewing(false)}
        templateId={template?.id}
      />
    </SavingStatusContextProvider>
  );
};