import { AppTopBar } from '@components/Layout/AppTopBar';
import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Stack, Toolbar, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SavingStatusChip } from './SavingStatusChip';
import { EditorSavingStatus, SavingStatusContext } from './SavingStatusContext';

export interface EditorTopBarProps {
  title: string | ReactNode;
  isLoading?: boolean;
  color?: 'primary' | 'secondary';
  topBarActions?: ReactNode;
}

export const EditorTopBar: React.FC<EditorTopBarProps> = ({ title, isLoading: isEditorLoading, topBarActions }) => {
  const navigate = useNavigate();

  const { editorSavingStatus } = useContext(SavingStatusContext);
  const isLoading = isEditorLoading || editorSavingStatus === EditorSavingStatus.Saving;


  return (
    <AppTopBar sx={{ backgroundColor: 'primary.main', padding: 0, boxShadow: 'none' }} elevation={0}>
      <Toolbar sx={{ color: (theme) => theme.palette.common.white }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} py={1} px={2}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconButton color='inherit' onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>

            {typeof title == 'string' ?
              <Typography color='inherit' variant='h5' sx={{ maxWidth: '80ch' }}>
                {title}
              </Typography>
              :
              title
            }

            {editorSavingStatus != null && (
              <SavingStatusChip status={editorSavingStatus} />
            )}
          </Stack>

          <Box>
            {topBarActions}
          </Box>
        </Stack>

        <LinearProgress style={{ visibility: isLoading ? 'visible' : 'hidden' }} />
      </Toolbar>
    </AppTopBar>
  );
};