import { Chip, Container, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { SubmissionStatus, useProjectFormSubmissionQuery, useProjectFormSubmissionsQuery } from '../../../../gql';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useGetMe } from '../../../../utils/useGetMe';
import { useCurrentProject } from '../../../projects/utils/useCurrentProject';
import { FormViewer } from '../../components/FormViewer';
import { FormViewerTopBar } from '../../components/FormViewerTopBar';
import { FormFillerView } from '../FormFillerView';

export const FormSubmissionPage = () => {
  const { submissionId: submissionIdString, formId: formIdString } = useParams();
  const selectedSubmissionId = submissionIdString ? Number(submissionIdString) : undefined;
  const formId = Number(formIdString);

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { me } = useGetMe();

  const { projectId } = useCurrentProject();
  const { data: submissions, isFetching: isFetchingSubmissions } = useProjectFormSubmissionsQuery({ projectId, formId }, { select: d => d.projectFormSubmissions });

  const { data: selectedSubmission, isFetching: isFetchingSelectedSubmission } = useProjectFormSubmissionQuery({ submissionId: selectedSubmissionId ?? -1 }, {
    keepPreviousData: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    select: d => d.projectFormSubmission,
    enabled: !!selectedSubmissionId
  });

  const loading = isFetchingSubmissions || isFetchingSelectedSubmission;

  const canFillForm = selectedSubmission?.status === SubmissionStatus.IsResponding && selectedSubmission.belongsToUserId == me?.id;

  return <>

    {selectedSubmission && !canFillForm && (
      <>

        <FormViewerTopBar
          selectedSubmissionId={selectedSubmission.id}
          submissions={submissions ?? []}
          isLoading={loading}
          onSubmissionSwitch={submissionId => navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${formId}/${submissionId}`, { replace: true })}
        />

        <Stack width='100%' overflow='auto'>
          <Container sx={{ py: 2, mb: 4, display: 'flex', gap: 2, flexDirection: 'column', height: '100%' }}>
            {selectedSubmission?.status !== SubmissionStatus.IsResponding && selectedSubmission?.belongsToUser && (
              <Stack direction='row' alignItems='center' gap={1}>
                <Typography variant='body2'>
                  {formatMessage({ id: 'Submitted by' })}
                </Typography>

                <Chip
                  icon={<UserAvatar displayName={selectedSubmission.belongsToUser.fullName} size='small' />}
                  label={selectedSubmission.belongsToUser.fullName}
                  size='small'
                  sx={{ color: 'inherit' }}
                />
              </Stack>
            )}

            {selectedSubmission.formDefinition && !canFillForm
              && <FormViewer definition={selectedSubmission.formDefinition} values={selectedSubmission.formValues} />}

          </Container>
        </Stack>
      </>
    )}

    {selectedSubmission?.formDefinition && canFillForm && selectedSubmissionId
      && <FormFillerView />}
  </>;
};

