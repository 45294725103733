import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { FileIcon } from '../../../../components/Icons/FileIcon';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../../projects/utils/useCurrentProject';
import { FormRow } from '../types';

export const FormNameCell: React.FC<GridRenderCellParams<FormRow>> = ({ row, formattedValue }) => {
  const navigate = useNavigate();

  const { projectId } = useCurrentProject();

  return (
    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center' overflow='hidden' >
      <FileIcon fileIconType={'InternalForm'} fontSize='large' />
      <Typography
        title={formattedValue}
        sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
        onClick={() => navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${row.id}`)}
      >
        {formattedValue}
      </Typography>

    </Stack>
  );
};