import { FieldType, MatrixColumnType, MatrixValueInput } from 'gql/index';

export interface FormEditorValues {
  id: number;
  name: string;
  description: string;
  sections: FormEditorSectionValues[];
  conditions: FormConditionValue[];
}

export interface FormConditionValue {
  id: number | '',
  targetFieldId: number | '',
  targetSectionId: number | '',
  conditionFieldId: number | '',
  conditionFieldWhenValueBool: boolean;
}

export interface FormEditorSectionValues {
  id?: number;
  name: string;
  fields: FormEditorFieldValues[];
}

export interface FormEditorFieldValues {
  id?: number,
  fieldType: FieldType,
  name: string;
  isRequired: boolean;
  isMultiline: boolean;
  isMultiselect: boolean;
  matrixValues?: FormEditorMatrixValues;
  selectionOptions: FormEditorSelectionOptions[];
}

export interface FormEditorSelectionOptions {
  id: string;
  label: string;
}

export interface FormEditorMatrixValues {
  columns: FormEditorMatrixColumnValues[];
  defaultRows: FormEditorMatrixRowValues[];

}

export interface FormEditorMatrixRowValues {
  rowId: string,
  values: MatrixEditorValue[];
}

export interface MatrixEditorValue extends MatrixValueInput {
  rowId: string;
  columnId: string;
}

export interface FormEditorMatrixColumnValues {
  columnId: string;
  type: MatrixColumnType;
  isRequired: boolean;
  name: string;
  description: string;
}

export interface MatrixEditorDataGridRow {
  id: string;
  values: MatrixEditorValue[];
  lastEditedColumnId?: string;
  newValue?: unknown;
}

export const FormEditorDroppableIds = {
  fieldsSelectionPanel: 'fieldsSelectionPanel',
  layoutSelectionPanel: 'layoutSelectionPanel',
  sections: 'sections'
};

export const FormEditorDroppableTypes = {
  field: 'field',
  section: 'section'
};

export const FieldIds = {
  text: 'text',
  multiline: 'multiline',
  numeric: 'numeric',
  boolean: 'boolean',
  date: 'date',
  matrix: 'matrix',
  selection: 'selection',
  file: 'file'
};

export const FormComponentIds = {
  ...FieldIds,
  section: 'section'
};

export const fieldIdToFieldTpe: Record<keyof typeof FieldIds, FieldType> = {
  text: FieldType.Text,
  multiline: FieldType.Text,
  numeric: FieldType.Numerical,
  boolean: FieldType.Boolean,
  date: FieldType.Date,
  matrix: FieldType.Matrix,
  selection: FieldType.Selection,
  file: FieldType.File
};