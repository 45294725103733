import { ConfirmDialog } from '@components/ConfirmDialog';
import { Property } from '@components/DataDisplay/Property';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { PageContainer } from '@components/Layout/PageContainer';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Archive, Edit, Unarchive } from '@mui/icons-material';
import { Button, Chip, Stack, Switch, Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useEditProjectMutation, useProjectDocumentsQuery, useProjectFormsQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentProject } from '../utils/useCurrentProject';
import { EditProjectDrawer } from './EditProjectDrawer';

export const ProjectSettings: React.FC = () => {
  const { projectId, name, description, createdBy, isArchived, hideBudgetFeature, query: { refetch } } = useCurrentProject();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [isUnarchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
  const [isEditProjectOpen, setEditProjectOpen] = useState(false);

  const { mutate: editProjectMutation, isLoading } = useEditProjectMutation({
    onSuccess: () => {
      refetch();
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      setArchiveDialogOpen(false);
      setUnarchiveDialogOpen(false);
    }
  });

  const onArchivingProject = (isArchived: boolean) => {
    editProjectMutation({
      input: {
        projectId,
        name: name ?? '',
        isArchived,
        hideBudgetFeature: hideBudgetFeature ?? false
      }
    }, {
      onSuccess: () => isArchived
        ? notifySuccess(formatMessage({ id: 'Project archived successfully' }))
        : notifySuccess(formatMessage({ id: 'Project reactivated successfully' }))
    });
  };

  const onBudgetHidden = (hideBudgetFeature: boolean) => {
    editProjectMutation({
      input: {
        projectId,
        name: name ?? '',
        isArchived: isArchived ?? false,
        hideBudgetFeature
      }
    }, {
      onSuccess: () => hideBudgetFeature
        ? notifySuccess(formatMessage({ id: 'Budget section hidden successfully' }))
        : notifySuccess(formatMessage({ id: 'Budget section shown successfully' }))
    });
  };

  return <>
    <PageContainer title={formatMessage({ id: 'Project settings' })}>
      <PageSection
        title={formatMessage({ id: 'Information' })}
        actionButton={
          <ResponsiveButton variant='contained' icon={<Edit />} disabled={isArchived || isLoading} onClick={() => setEditProjectOpen(true)}>
            {formatMessage({ id: 'Edit' })}
          </ResponsiveButton>
        }
      >
        <PageSectionPart>
          <TextProperty
            propertyName={formatMessage({ id: 'Name' })}
            propertyValue={name}
          />
        </PageSectionPart>

        <PageSectionPart>
          <TextProperty
            propertyName={formatMessage({ id: 'Description' })}
            propertyValue={description}
            emptyText={formatMessage({ id: 'No description provided' })}
          />
        </PageSectionPart>

        <PageSectionPart>
          <Property propertyName={formatMessage({ id: 'Created by' })} emptyState={formatMessage({ id: 'Nobody assigned yet' })}>
            <Chip
              label={createdBy?.fullName}
              avatar={<UserAvatar displayName={createdBy?.fullName} />}
              style={{ width: 'min-content' }}
            />
          </Property>
        </PageSectionPart>
      </PageSection>

      <PageSection title={formatMessage({ id: 'Optional features' })}>
        <PageSectionPart>
          <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' justifyContent='space-between' gap={2}>
            <Typography variant='body1'>
              {formatMessage({ id: 'Hide budget section' })}
            </Typography>
            <Switch
              disabled={isLoading}
              checked={hideBudgetFeature ?? false}
              onChange={(_, checked) => onBudgetHidden(checked)}
            />
          </Stack>
        </PageSectionPart>
      </PageSection>

      {!isArchived &&
        <PageSection title={formatMessage({ id: 'Archive' })}>
          <PageSectionPart>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' gap={2}>
              <Stack>
                <Typography variant='body1'>
                  {formatMessage({ id: 'Archive project' })}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {formatMessage({ id: 'Mark this project as Archived. This will prevent any further changes to the project.' })}
                </Typography>
              </Stack>
              <Button disabled={isLoading} variant='contained' color='warning' startIcon={<Archive />} onClick={() => setArchiveDialogOpen(true)} size='small'>
                {formatMessage({ id: 'Archive' })}
              </Button>
            </Stack>
          </PageSectionPart>
        </PageSection>
      }

      {isArchived && (
        <PageSection title={formatMessage({ id: 'Reactivate' })}>
          <PageSectionPart>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' gap={2}>
              <Stack>
                <Typography variant='body1'>
                  {formatMessage({ id: 'Reactivate project' })}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {formatMessage({ id: 'This will reactivate the project, allowing it to be modified again.' })}
                </Typography>
              </Stack>
              <Button disabled={isLoading} variant='contained' color='primary' startIcon={<Unarchive />} onClick={() => setUnarchiveDialogOpen(true)} size='small'>
                {formatMessage({ id: 'Reactivate' })}
              </Button>
            </Stack>
          </PageSectionPart>
        </PageSection>
      )}
    </PageContainer>

    <EditProjectDrawer
      open={isEditProjectOpen}
      onClose={() => setEditProjectOpen(false)}
    />

    <ConfirmDialog
      title={formatMessage({ id: 'Archive this project?' })}
      open={isArchiveDialogOpen}
      onCancel={() => setArchiveDialogOpen(false)}
      onConfirm={() => onArchivingProject(true)}
      confirmColor='warning'
      loading={isLoading}
      content={formatMessage({ id: 'You will no longer be able to modify documents and forms, but you will still be able to manage access to the project.' })}
    />

    <ConfirmDialog
      title={formatMessage({ id: 'Reactivate this project?' })}
      open={isUnarchiveDialogOpen}
      onCancel={() => setUnarchiveDialogOpen(false)}
      onConfirm={() => onArchivingProject(false)}
      confirmColor='info'
      loading={isLoading}
      content={formatMessage({ id: 'This will restore the project to an editable state.' })}
    />
  </>;
};
