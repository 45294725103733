import { UnstyledLink } from '@components/UnstyledLink';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, Card, CardActionArea, CardMedia, Divider, Stack, Typography } from '@mui/material';
import { Project as ProjectGQL } from 'gql/index';
import { useIntl } from 'react-intl';

type Project = Pick<ProjectGQL, 'name' | 'description' | 'createdOn' | 'id' | 'isArchived'> & {
  securityGroups: { members: Pick<ProjectGQL['securityGroups'][0]['members'][0], 'id' | 'fullName'>[]; }[];
};

interface Props {
  project: Project;
}

export const ProjectCard: React.FC<Props> = ({ project }) => {
  const { formatDate } = useIntl();

  return (
    <Card style={{ width: '100%', height: '200px' }}>
      <UnstyledLink to={`${project.id.toString()}/home`}>
        <CardActionArea sx={{ height: '100%' }}>
          <Stack height='100%'>
            <CardMedia component='div' sx={{ height: 24, backgroundColor: project.isArchived ? 'action.disabled' : 'secondary.main' }} />

            <Stack flexGrow={1} p={1} overflow='hidden'>
              <Stack>
                <Typography variant='h6' maxHeight='80px' color={project.isArchived ? 'text.secondary' : 'text.primary'} style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                  {project.name}
                </Typography>

                <Typography variant='subtitle2' color='text.secondary'>{formatDate(project.createdOn)}</Typography>
              </Stack>

              {project.description && (
                <Typography variant='body2' color='text.secondary' overflow='hidden' textOverflow='ellipsis' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                  {project.description}
                </Typography>
              )}
            </Stack>

            <Divider />

            <Stack alignItems='start' p={1}>
              <AvatarGroup sx={{ '& .MuiAvatar-root': { height: '32px', width: '32px', fontSize: 12 } }}>
                {project.securityGroups.flatMap(p => p.members).map(user => (
                  <UserAvatar key={user.id} displayName={user.fullName} />
                ))}
              </AvatarGroup>
            </Stack>
          </Stack>
        </CardActionArea>
      </UnstyledLink>
    </Card>
  );
};