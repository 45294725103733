import { FormDialog } from '@components/Dialogs/FormDialog';
import { DialogProps, ListItemText, Stack, Switch, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useAddProjectMutation, useGetProjectsQuery } from 'gql/index';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

export interface ProjectFormValues {
  name: string;
  description: string;
  optInToBudgetFeature: boolean;
}

const defaultValues: ProjectFormValues = {
  name: '',
  description: '',
  optInToBudgetFeature: false
};

export const AddProjectDialog: React.FC<DialogProps> = ({ ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { mutate: addProject, isLoading } = useAddProjectMutation({
    onSuccess: () => {
      invalidateQuery(useGetProjectsQuery);
      dialogProps.onClose?.({}, 'escapeKeyDown');
      notifySuccess(formatMessage({ id: 'Project created successfully' }));
    }
  });

  const onSubmit = (values: ProjectFormValues) => {
    addProject({
      input: {
        name: values.name,
        description: values.description ? values.description : undefined,
        hideBudgetFeature: !values.optInToBudgetFeature,
      }
    });
  };

  const disabled = isLoading;

  const { control, reset, handleSubmit } = useForm<ProjectFormValues>({ defaultValues });

  const nameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    nameRef.current?.focus();
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset]);

  return (
    <FormDialog
      title={formatMessage({ id: 'Create project' })}
      {...dialogProps}
      maxWidth='sm'
      fullWidth
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      disableClose={isLoading}
    >
      <Controller
        control={control}
        name='name'
        rules={{ required: formatMessage({ id: 'This field is required' }) }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            inputRef={nameRef}
            {...field}
            disabled={disabled}
            required
            label={formatMessage({ id: 'Name' })}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='description'
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            disabled={disabled}
            label={formatMessage({ id: 'Description' })}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='optInToBudgetFeature'
        render={({ field }) => (
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <ListItemText
              primary={formatMessage({ id: 'Opt in to budget feature' })}
              secondary={formatMessage({ id: 'This setting can be changed in the future' })}
            />
            <Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />
          </Stack>
        )}
      />
    </FormDialog>
  );
};