import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as LinkIcon, Timeline } from '@mui/icons-material';
import TaskAlt from '@mui/icons-material/TaskAlt';
import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FileIcon } from '../../../../components/Icons/FileIcon';
import { ShareableResourceType } from '../../../../gql';
import { ShareableDocument, ShareableExternalLink, ShareableFolder, ShareableForm, ShareableFormTemplate, ShareableResource, ShareableTask } from './types';

interface Props {
  resource: ShareableResource;
}

// The ShareableResourceType should be typed to the correct variant of ShareableResource
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typeToOutput: Record<ShareableResourceType, (resource: any) => ReactNode> = {
  Document: (resource: ShareableDocument) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <FileIcon fileName={resource.fileName} fontSize='medium' />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }} >{resource.fileName}</Typography>
    </Stack>
  ),
  Folder: (resource: ShareableFolder) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <FontAwesomeIcon icon={faFolder} color='orange' />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }} >{resource.name}</Typography>
    </Stack>
  ),
  Task: (resource: ShareableTask) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <TaskAlt />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>{resource.name}</Typography>
    </Stack>
  ),
  Form: (resource: ShareableForm) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <FileIcon fileIconType='InternalForm' />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>{resource.name}</Typography>
    </Stack>
  ),
  FormTemplate: (resource: ShareableFormTemplate) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <FileIcon fileIconType='InternalForm' />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>{resource.name}</Typography>
    </Stack>
  ),
  ExternalLink: (resource: ShareableExternalLink) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <LinkIcon />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>{resource.name}</Typography>
    </Stack>
  ),
  WorkflowDefinition: (resource) => (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Timeline />
      <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>{resource.name}</Typography>
    </Stack>
  ),
};

export const ShareableResourceItem: React.FC<Props> = ({ resource }) => {
  return typeToOutput[resource.resourceType](resource);
};