import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { Droppable } from '@hello-pangea/dnd';
import { Paper, alpha } from '@mui/material';
import { FieldType, useCreateFormFieldMutation, useDeleteFormFieldMutation, useEditFormFieldMutation, useMoveFormFieldMutation } from 'gql/index';
import { useCallback, useContext, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EditorSavingStatus, SavingStatusContext } from '../../../../../components/Editor/TopBar/SavingStatusContext';
import { getBorderColor } from '../../../../../components/Utils/DroppableComponentUtils';
import { DroppableEmptyState } from '../DroppableEmptyState';
import { useAllFieldsMovedListener, useFieldCreatedListener, useFieldMovedListener } from '../events';
import { FormEditorDroppableTypes, FormEditorFieldValues, FormEditorValues } from '../types';
import { mapFieldFragmentToFormValues, matrixFormValuesToMutationValues } from '../utils';
import { DraggableFormField } from './DraggableFormField';

interface Props {
  sectionIndex: number;
  sectionId: number;
}

export const DroppableFormFields: React.FC<Props> = ({ sectionIndex, sectionId }) => {
  const { formatMessage } = useIntl();
  const { control, setValue, getValues } = useFormContext<FormEditorValues>();

  const { fields, insert, prepend, remove, move, update } = useFieldArray({ control, name: `sections.${sectionIndex}.fields`, keyName: 'uniqueId' });
  const { setEditorSavingStatus, editorSavingStatus } = useContext(SavingStatusContext);

  const formId = useWatch({ control, name: 'id' });

  const { onEditionError, onEditionSuccess } = useEditorHelpers();

  const { mutate: createFormField, isLoading: isCreatingFormField } = useCreateFormFieldMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: editFormField, isLoading: isEditingFormField } = useEditFormFieldMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: moveFormField, isLoading: isMovingFormField } = useMoveFormFieldMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: deleteFormField, isLoading: isDeletingFormField } = useDeleteFormFieldMutation({ onError: onEditionError, onSuccess: onEditionSuccess });

  const isSaving = isCreatingFormField || isEditingFormField || isMovingFormField || isDeletingFormField;

  useEffect(() => {
    if (isSaving && editorSavingStatus !== EditorSavingStatus.Saving) {
      setEditorSavingStatus(EditorSavingStatus.Saving);
    }
  }, [editorSavingStatus, isSaving, setEditorSavingStatus]);

  useFieldCreatedListener((payload) => {
    if (payload.destinationId !== sectionId.toString()) return;

    insert(payload.createdAtIndex, {
      name: '',
      fieldType: payload.fieldType,
      isRequired: false,
      isMultiline: payload.isMultiline,
      isMultiselect: false,
      selectionOptions: []
    });
  });

  useFieldMovedListener((payload) => {
    const isSource = payload.sourceSectionId === sectionId.toString();
    const isDestination = payload.destinationSectionId === sectionId.toString();

    if (isSource && !isDestination) {
      remove(payload.sourceIndex);
    } else if (isSource && isDestination) {
      move(payload.sourceIndex, payload.destinationIndex);
    } else if (!isSource && isDestination) {
      insert(payload.destinationIndex, payload.field);
    }

    if (isDestination && payload.field.id) {
      const fieldIds = fields.map(f => f.id);
      if (isSource) {
        fieldIds.splice(payload.sourceIndex, 1);
      }
      fieldIds.splice(payload.destinationIndex, 0, payload.field.id);
      const backendFieldIndex = fieldIds.filter(f => !!f && f > 0).indexOf(payload.field.id);

      moveFormField({
        input: {
          formDefinitionId: formId,
          fieldId: payload.field.id,
          destinationSectionId: sectionId,
          newIndex: backendFieldIndex
        }
      });
    }
  });

  useAllFieldsMovedListener(payload => {
    if (payload.destinationSectionId !== sectionId.toString()) return;

    for (const field of payload.fields) {
      prepend(field);
    }
  });

  const onFieldSubmitted = useCallback((index: number, field: FormEditorFieldValues) => {
    const fieldId = getValues(`sections.${sectionIndex}.fields.${index}.id`);

    const matrixInput = field.fieldType === FieldType.Matrix
      ? matrixFormValuesToMutationValues(field.matrixValues)
      : undefined;

    if (fieldId) {
      editFormField({
        input: {
          formDefinitionId: formId,
          fieldId: fieldId ?? 0,
          name: field.name,
          isRequired: field.isRequired,
          isMultiline: field.isMultiline,
          isMultiselect: field.isMultiselect,
          options: field.selectionOptions.map(o => ({ label: o.label })),
          matrixInput
        }
      }, {
        onSuccess: (response) => {
          response.editFormField.formField
            && setValue(`sections.${sectionIndex}.fields.${index}`, mapFieldFragmentToFormValues(response.editFormField.formField));
        }
      });
    } else {
      createFormField({
        input: {
          formDefinitionId: formId,
          sectionId,
          order: index,
          fieldType: field.fieldType,
          isRequired: field.isRequired,
          isMultiline: field.isMultiline,
          isMultiselect: field.isMultiselect,
          options: field.selectionOptions.map(o => ({ label: o.label })),
          name: field.name,
          matrixInput
        }
      }, {
        onSuccess: d => {
          const newField = d.createFormField?.formField;
          newField && update(index, mapFieldFragmentToFormValues(newField));
        }
      });
    }
  }, [createFormField, editFormField, formId, getValues, sectionId, sectionIndex, setValue, update]);

  const onFieldDeleted = useCallback((index: number) => {
    const id = getValues(`sections.${sectionIndex}.fields.${index}.id`);

    if (!id) return remove(index);

    deleteFormField({
      input: {
        formDefinitionId: formId,
        id: id
      }
    }, {
      onSuccess: () => {
        remove(index);
      }
    });
  }, [deleteFormField, formId, getValues, remove, sectionIndex]);

  return (
    <Droppable type={FormEditorDroppableTypes.field} droppableId={String(sectionId)}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            bgcolor: t => snapshot.isDraggingOver ? alpha(t.palette.primary.main, t.palette.action.hoverOpacity) : t.palette.background.paper,
            boxShadow: 'none',
            border: getBorderColor(snapshot.isDraggingOver, fields.length)
          }}
        >
          {fields.map((field, index) => (
            <DraggableFormField
              key={field.uniqueId}
              onSubmit={field => onFieldSubmitted(index, field)}
              index={index}
              field={field}
              onDelete={() => onFieldDeleted(index)}
            />
          ))}

          {provided.placeholder}
          {fields.length === 0 && (
            <DroppableEmptyState placeholderText={formatMessage({ id: 'Fields can be dropped here' })} visibility={snapshot.isDraggingOver} />
          )}
        </Paper>
      )}
    </Droppable>
  );
};