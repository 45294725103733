import { ActionMenu } from '@components/ActionMenu';
import { FolderEntryMoveActions } from '@modules/folders/components/FolderEntryMoveActions';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete, Publish, RemoveRedEye, SaveAs, Settings } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Button, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ApplicationUser, FolderEntryFragmentFragment, FormSettings, FormStatus, FormType, ProjectForm, useCreateTemplateFromProjectFormMutation, useDeleteProjectFormMutation, useProjectFormOverviewsQuery, useProjectFormQuery, useProjectFormSubmissionsQuery, useProjectFormsQuery, useProjectTasksQuery, useRequestFormSubmissionMutation, useUnpublishFormMutation } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useNotification } from '../../../utils/useNotification';
import { useFormSecurity } from '../hooks/useFormSecurity';
import { FormSettingsDrawer } from './FormSettings/FormSettingsDrawer';
import { CollectResponsesDialog } from './PublishForm/CollectResponsesDialog';
import { PublishFormDialog } from './PublishForm/PublishFormDialog';

interface Props {
  expanded?: boolean;
  onPreviewClick?: () => void;
  projectForm: Pick<ProjectForm, 'id' | 'status'> & {
    'formSettings': Pick<FormSettings, 'formType' | 'allowedRespondents'> &
    { 'allowedUsersRespondents': Pick<ApplicationUser, 'id'>[] }
    & { 'allowedSecurityGroupsRespondents': { 'members': Pick<ApplicationUser, 'id'>[] }[] };
  };
  folderEntry?: FolderEntryFragmentFragment;
}

export const FormActions: React.FC<Props> = ({ projectForm, expanded, folderEntry, onPreviewClick }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { projectId, isArchived } = useCurrentProject();
  const { canManageForms } = useCurrentProject();
  const { formId: openFormId } = useParams();

  const [publishOpen, setPublishOpen] = useState(false);
  const [collectResponsesOpen, setCollectResponsesOpen] = useState(false);
  const [unpublishConfirmOpen, setUnpublishConfirmOpen] = useState(false);

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { canRequestFormSubmission } = useFormSecurity(projectForm);
  const { mutate: requestSubmission, isLoading: isRequestingSubmission } = useRequestFormSubmissionMutation();

  const onRequestSubmissionClicked = () => {
    requestSubmission({ input: { formId: projectForm.id } }, {
      onSuccess: d => {
        const id = d.requestFormSubmission.projectFormSubmission?.id;
        if (!id) return;
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${projectForm.id}/${id}`);
      }
    });
  };

  const { mutate: deleteProjectForm, isLoading: isDeleting } = useDeleteProjectFormMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Form deleted successfully' }));
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectFormOverviewsQuery, { projectId });
      invalidateQuery(useProjectFormSubmissionsQuery, { projectId });
      setConfirmRemoveDialogOpen(false);
      if (openFormId) {
        navigate(-1);
      }
    }
  });

  const { mutate: unpublishForm, isLoading: isUnpublishing } = useUnpublishFormMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Form unpublished successfully' }));
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormQuery, { formId: projectForm.id });
      invalidateQuery(useProjectFormOverviewsQuery, { projectId });
      setUnpublishConfirmOpen(false);
    }
  });

  const { mutate: createTemplateFromProjectForm, isLoading: isLoadingSaveAsTemplate } = useCreateTemplateFromProjectFormMutation({
    onSuccess: data => {
      notifySuccess(formatMessage({ id: 'Form saved as template successfully' }));
      invalidateQuery(useProjectFormsQuery, { projectId });
      navigate(`/${getTenantIdentifier()}/templates/edit/${data.createTemplateFromProjectForm.formTemplate?.id}`);
    }
  });

  const handleSaveAsTemplateClick = () => {
    createTemplateFromProjectForm({
      input: {
        projectFormId: projectForm.id
      }
    });
  };

  const onDeleteConfirm = () => {
    projectForm && deleteProjectForm({ input: { formId: projectForm.id, projectId } });
  };

  const onUnpublishConfirm = () => {
    projectForm && unpublishForm({ input: { projectId: projectId, projectFormId: projectForm.id } });
  };


  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);

  if (!canManageForms) return null;

  return (
    <>
      <Stack direction='row' gap={2}>

        {expanded && <>
          {onPreviewClick && (
            <Button startIcon={<RemoveRedEye />} color='inherit' onClick={onPreviewClick}>
              {formatMessage({ id: 'Preview' })}
            </Button>
          )}

          {projectForm.formSettings.formType == FormType.SelfServe && projectForm.status === FormStatus.Draft && (
            <Button startIcon={<Publish />} variant='contained' color='primary' onClick={() => setPublishOpen(true)} disabled={isArchived}>
              {formatMessage({ id: 'Publish' })}
            </Button>
          )}

          {projectForm.formSettings.formType == FormType.CollectResponse && (
            <Button startIcon={<Publish />} variant='contained' color='primary' onClick={() => setCollectResponsesOpen(true)} disabled={isArchived}>
              {formatMessage({ id: 'Send form' })}
            </Button>
          )}


          {projectForm.status == FormStatus.Published && (
            <Button startIcon={<CloudOffIcon />} variant='outlined' color='warning' onClick={() => setUnpublishConfirmOpen(true)} disabled={isArchived}>
              {formatMessage({ id: 'Unpublish' })}
            </Button>
          )}
        </>}

        <ActionMenu open={moreMenuDrawerOpen} onOpen={setMoreMenuDrawerOpen}>

          {canRequestFormSubmission && onRequestSubmissionClicked && !isArchived && (
            <MenuItem onClick={onRequestSubmissionClicked} disabled={isRequestingSubmission || isArchived}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Fill the form' })} />
            </MenuItem>
          )}

          {!expanded && onPreviewClick && isArchived && (
            <MenuItem onClick={onPreviewClick}>
              <ListItemIcon><RemoveRedEye /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Preview' })} />
            </MenuItem>
          )}

          {!expanded && projectForm.formSettings.formType == FormType.SelfServe && projectForm.status == FormStatus.Draft && projectForm && (
            <MenuItem onClick={() => setPublishOpen(true)} disabled={isArchived}>
              <ListItemIcon><Publish /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Publish' })} />
            </MenuItem>
          )}

          {!expanded && projectForm.formSettings.formType == FormType.SelfServe && projectForm.status == FormStatus.Published && (
            <MenuItem onClick={() => setUnpublishConfirmOpen(true)} disabled={isArchived}>
              <ListItemIcon><Publish /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Unpublish' })} />
            </MenuItem>
          )}

          {!expanded && projectForm.formSettings.formType == FormType.CollectResponse && projectForm && (
            <MenuItem onClick={() => setCollectResponsesOpen(true)} disabled={isArchived}>
              <ListItemIcon><Publish /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Send form' })} />
            </MenuItem>
          )}



          {projectForm.formSettings.formType === FormType.SelfServe && (
            <MenuItem onClick={() => setIsSettingsOpen(true)} disabled={isArchived}>
              <ListItemIcon><Settings /></ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Settings' })} />
            </MenuItem>
          )}

          {folderEntry && (
            <FolderEntryMoveActions entry={folderEntry} setMoreMenuDrawerOpen={setMoreMenuDrawerOpen} />
          )}

          <MenuItem onClick={handleSaveAsTemplateClick} disabled={isArchived || isLoadingSaveAsTemplate}>
            <ListItemIcon><SaveAs /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Save as template' })} />
          </MenuItem>

          <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)} disabled={isArchived}>
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Delete' })} />
          </MenuItem>
        </ActionMenu>
      </Stack>

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete form' })}
        content={formatMessage({ id: 'This form will be deleted. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isDeleting}
      />

      <ConfirmDialog
        open={unpublishConfirmOpen}
        title={formatMessage({ id: 'Unpublish form' })}
        content={formatMessage({ id: 'This form will no longer be available as a self-serve forms to project members. Are you sure?' })}
        confirmText={formatMessage({ id: 'Unpublish' })}
        confirmColor='warning'
        onCancel={() => setUnpublishConfirmOpen(false)}
        onConfirm={onUnpublishConfirm}
        loading={isUnpublishing}
      />

      <PublishFormDialog
        open={publishOpen}
        onClose={() => setPublishOpen(false)}
        formId={projectForm.id}
      />

      <CollectResponsesDialog
        open={collectResponsesOpen}
        onClose={() => setCollectResponsesOpen(false)}
        formId={projectForm.id}
      />

      <FormSettingsDrawer projectFormId={projectForm.id} open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </>
  );
};