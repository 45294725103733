import { Draggable } from '@hello-pangea/dnd';
import { DragHandle } from '@mui/icons-material';
import { Card, CardActionArea, CardActions, CardContent, Chip, ListItemSecondaryAction, Stack, Typography } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ActionIconByType } from '../../WorflowInstance/Actions/Types';
import { AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorValues } from '../types';
import { WorkflowActionConfigurationActions } from './WorkflowActionConfigurationActions';

interface Props {
  actionIndex: number;
}

export const ActionTile: React.FC<Props> = ({ actionIndex }) => {
  const { setSelectedActionIndex, selectedActionIndex, disabled } = useContext(WorkflowEditorContext);
  const { control } = useFormContext<WorkflowEditorValues>();
  const { workflowDefinition, findField } = useContext(WorkflowEditorContext);
  const isSelected = actionIndex === selectedActionIndex;

  const action = useWatch({ control: control, name: `actions.${actionIndex}` });

  const partiticipants = action.fieldValues
    .filter(p => findField(action.workflowActionDefinitionId, p.fieldId)?.variableType === WorkflowVariableValueTypes.User)
    .map(p => workflowDefinition.variables.find(q => q.id === p.value?.id))
    .filter(notNullOrUndefined);

  return (
    <Draggable isDragDisabled={disabled} draggableId={action.id.toString()} index={actionIndex}>{provided => (
      <Stack ref={provided.innerRef} {...provided.draggableProps} alignItems={'center'}>
        <Card
          sx={{
            position: 'relative',
            width: 400,
            border: isSelected ? '1px solid' : 'default',
            backgroundColor: isSelected ? 'action.selected' : 'default'
          }}
        >
          <CardActionArea disabled={disabled} onClick={() => setSelectedActionIndex(actionIndex)}>
            <Stack p={1} justifyContent={'center'} alignItems={'center'} {...provided.dragHandleProps} sx={{ visibility: disabled ? 'hidden' : 'visible' }}>
              <DragHandle />
            </Stack>

            <CardContent sx={{ pt: 0 }}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} flex={1}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {ActionIconByType[action.workflowActionDefinitionId as AllActionIds]}
                  <Typography variant='body1' fontWeight={600}>
                    {action.name}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>

            <CardActions sx={{ p: 2 }} >
              {partiticipants.map(p => <Chip size='small' key={p.id} label={p.name}></Chip>)}
            </CardActions>
          </CardActionArea>

          {!disabled && (
            <ListItemSecondaryAction>
              <WorkflowActionConfigurationActions workflowTemplateId={workflowDefinition.id} workflowActionConfiguration={action} />
            </ListItemSecondaryAction>
          )}
        </Card>
      </Stack>
    )}</Draggable>
  );
};