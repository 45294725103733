import { PageContainer } from '@components/Layout/PageContainer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Timeline, timelineConnectorClasses, timelineItemClasses, timelineSeparatorClasses } from '@mui/lab';
import { Container, styled } from '@mui/material';
import { useActionsOfWorkflowInstanceQuery, useWorkflowInstanceQuery } from 'gql/index';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { ActionComponent } from '../components/WorflowInstance/Actions/ActionComponent';

const StyledTimeline = styled(Timeline)(() => ({
  p: 0,
  [`& .${timelineItemClasses.root}:before`]: {
    display: 'none',
  },
  [`& .${timelineSeparatorClasses.root}`]: {
    flex: 1,
  },
  [`& .${timelineConnectorClasses.root}`]: {
    minHeight: 40,
    mt: 2
  }
}));

export const WorkflowPage: React.FC = () => {
  const { workflowId: workflowIdString } = useParams();
  const workflowId = Number(workflowIdString) || 0;

  const { projectId } = useCurrentProject();
  const { data: workflow, isFetching: isLoadingWorkflow } = useWorkflowInstanceQuery({ projectId, workflowId }, { select: d => d.projectWorkflow });
  const { data: actions, isFetching: isLoadingActions } = useActionsOfWorkflowInstanceQuery({ workflowId }, { select: d => d.workflowActionConfigurationsOfWorkflow });

  const isLoading = isLoadingActions || isLoadingWorkflow;

  return <PageContainer title={workflow?.name} fullPageLoading={isLoading}>
    <Container maxWidth='sm'>
      <StyledTimeline>
        {actions && _.orderBy(actions, p => p.order).map((a) =>
          <ActionComponent key={a.id} workflowId={workflowId} action={a} />
        )}
      </StyledTimeline>
    </Container>
  </PageContainer>;
};