import { FormDefinitionFragmentFragment, FormValueFragmentFragment, FormValueInput, MatrixRowFragmentFragment } from 'gql/index';
import { isNumber } from 'lodash';
import { FormFieldValues, FormFillerValues, MatrixFillerRows, MatrixFillerValue, formFieldDefaultValues } from './types';

type Field = FormDefinitionFragmentFragment['sections'][0]['fields'][0];

export type FormFillerMode = 'fill' | 'preview' | 'submitted';

export const matrixRowsToMatrixFillerRows = (rows: MatrixRowFragmentFragment[]): MatrixFillerRows[] => rows.map<MatrixFillerRows>(row => ({
  id: row.id,
  isDefaultRow: row.isDefaultRow,
  values: row.values?.map<MatrixFillerValue>(value => ({
    id: value.id,
    rowId: row.id,
    columnId: value.matrixColumnId,
    text: value.text ?? '',
    number: value.number == null ? null : value.number,
    boolean: value.boolean == null ? null : value.boolean
  }))
}));

export const formValueToFormFillerValue = (value: FormValueFragmentFragment, submissionId: number): FormFieldValues => ({
  fieldId: value.fieldId,
  submissionId,
  text: value.text ?? '',
  numerical: value.number == null ? '' : value.number.toString(),
  boolean: value.boolean == null ? '' : value.boolean ? 'true' : 'false',
  date: value.dateValue ?? '',
  selectedOptions: value.selectedOptions?.map(o => o.id) ?? [],
  matrixRows: matrixRowsToMatrixFillerRows(value.matrixRows),
  files: value.files.map(p => ({ id: p.id, fileName: p.fileName }))
});

export const backendValuesToFormFillerValues = (fields: Field[], values: FormValueFragmentFragment[], submissionId: number): FormFieldValues[] =>
  fields.map<FormFieldValues>(field => {
    const value = values.find(v => v.fieldId === field.id);

    if (!value) return formFieldDefaultValues(field, submissionId);

    const newValue = formValueToFormFillerValue(value, submissionId);

    return newValue;
  });


export const formFillerValuesToMutationValues = ({ values }: FormFillerValues): FormValueInput[] => {
  const result: FormValueInput[] = [];

  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    result.push(formFillerValueToFormValueInput(value));
  }

  return result;
};

export const formFillerValueToFormValueInput = (value: FormFieldValues): FormValueInput => {
  if (!isNumber(value.fieldId)) {
    throw new Error('FieldId property is not a number.');
  }

  return {
    fieldId: value.fieldId,
    submissionId: value.submissionId,
    text: value.text,
    number: value.numerical ? Number(value.numerical) : null,
    boolean: value.boolean === 'true' ? true : value.boolean === 'false' ? false : null,
    dateValue: value.date.trim() === '' ? null : value.date,
    selectedOptionIds: value.selectedOptions,
  };
};