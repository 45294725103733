
import { FormDialog } from '@components/Dialogs/FormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { DialogProps, FormControl, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrincipalType, useCollectFormResponsesMutation, useGetProjectMembersQuery, useProjectFormDefinitionQuery, useProjectFormOverviewsQuery, useProjectFormQuery, useProjectFormsQuery, useProjectTasksQuery } from '../../../../gql';

interface Props extends DialogProps {
  formId: number;
}

export interface CollectResponsesFormValues {
  userIds: string[];
  customMessage: string;
  shouldCreateMandatoryRequests: boolean;
  mandatoryRequestDueDate: string;
}

export const CollectResponsesDialog: React.FC<Props> = ({ formId, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { mutate: collectResponses, isLoading: isCollectingResponses } = useCollectFormResponsesMutation({
    onSuccess: () => {
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectFormQuery, { formId: formId });
      invalidateQuery(useProjectFormDefinitionQuery, { formId: formId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormOverviewsQuery, { projectId });

      notifySuccess(formatMessage({ id: 'Form sent successfully' }));
      dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
    }
  });

  const onSubmit = (formValues: CollectResponsesFormValues) => {
    collectResponses({
      input: {
        projectId: projectId,
        projectFormId: formId,
        userIds: formValues.userIds,
        customMessage: formValues.customMessage?.trim() || undefined,
        mandatoryRequestDueDate: formValues.mandatoryRequestDueDate === '' ? undefined : formValues.mandatoryRequestDueDate

      }
    });
  };

  const { control, handleSubmit, reset } = useForm<CollectResponsesFormValues>({
    defaultValues: {
      customMessage: '',
      userIds: [],
      mandatoryRequestDueDate: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    reset();
  }, [reset, dialogProps.open]);


  return (
    <FormDialog
      title={formatMessage({ id: 'Send form' })}
      {...dialogProps}
      maxWidth='xs'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isCollectingResponses}
    >
      <Controller
        control={control}
        name='userIds'
        rules={{ required: formatMessage({ id: 'This field is required' }) }}
        render={({ field, fieldState: { error } }) =>
          <FormControl>
            <PrincipalPicker
              multiple
              usersOnly
              value={field.value.map(id => ({ principalType: PrincipalType.User, id }))}
              onChange={u => field.onChange(u.map(u => u.id))}
              label={formatMessage({ id: 'Responders' })}
              required
              error={error?.message}
            />
          </FormControl>
        }
      />

      <Controller
        control={control}
        name='mandatoryRequestDueDate'
        rules={{ required: formatMessage({ id: 'This field is required' }) }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            required
            {...field}
            type='date'
            fullWidth
            label={formatMessage({ id: 'Due date' })}
            error={!!error}
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <Controller
        control={control}
        name='customMessage'
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={formatMessage({ id: 'Notes to recipient (optional)' })}
            multiline
            rows={6}
            error={error != null}
            helperText={error?.message}
          />
        )}
      />
    </FormDialog >
  );
};