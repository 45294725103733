import { useFormSecurity } from '@modules/forms/hooks/useFormSecurity';
import { useGetFilledForms } from '@modules/forms/utils/useGetFilledForms';
import { LoadingButton } from '@mui/lab';
import { Container, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useProjectFormQuery, useRequestFormSubmissionMutation } from '../../../../gql';
import { SubmissionsTable } from '../../components/SubmissionsTable';

export const FilledFormsTab = () => {
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { data: form, isFetching: isFetchingForm } = useProjectFormQuery({ formId }, { select: d => d.projectForm });
  const { submissions, isFetching: isFetchingSubmissions } = useGetFilledForms();
  const { canRequestFormSubmission } = useFormSecurity(form);

  const { mutate: requestSubmission, isLoading: isRequestingSubmission } = useRequestFormSubmissionMutation();

  const onRequestSubmissionClicked = () => {
    requestSubmission({ input: { formId } }, {
      onSuccess: d => {
        const id = d.requestFormSubmission.projectFormSubmission?.id;
        if (!id) return;
        navigate(id.toString());
      }
    });
  };

  return <Stack overflow='auto'>
    <Container sx={{ py: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>
          {formatMessage({ id: 'Forms' })}
        </Typography>

        {canRequestFormSubmission && (
          <LoadingButton variant='contained' onClick={onRequestSubmissionClicked} loading={isRequestingSubmission}>
            {formatMessage({ id: 'Fill the form' })}
          </LoadingButton>
        )}
      </Stack>

      <SubmissionsTable
        submissions={submissions ?? []}
        loading={isFetchingSubmissions || isFetchingForm}
      />

    </Container>
  </Stack>;
};

