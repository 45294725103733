import { FormDrawer } from '@components/Drawers/FormDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { SecurityPrincipalShim } from '@modules/users/components/types';
import { useProjectPrincipals } from '@modules/users/components/useProjectPrincipals';
import { Alert, DrawerProps, FormControl, MenuItem, Stack, TextField } from '@mui/material';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs from 'dayjs';
import { PrincipalType, ProjectTaskStatus, ProjectTaskType, useAddTaskMutation, useGetProjectBudgetQuery, useMyTasksQuery, useParentTaskQuery, useProjectTaskQuery, useProjectTaskSubtasksQuery, useProjectTasksQuery, useUpdateTaskMutation } from 'gql/index';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getTaskStatusOrderingKey, useTaskMessages } from '../taskUtils';
import { TaskPredecessorsPicker } from './TaskPredecessorsPicker';
import { TaskSelectInput } from './TaskSelectInput/TaskSelectInput';
import { TaskStatusDisplay } from './TaskStatusDisplay';

interface Props extends DrawerProps {
  taskId?: number;
  parentTaskId?: number;
}

interface FormValues {
  name: string;
  description: string;
  startDate: string | '';
  dueDate: string | '';
  assignees: SecurityPrincipalShim[];
  status: ProjectTaskStatus;
  predecessorIds: number[];
  parentId: number | null;
}

const defaultValues: FormValues = {
  name: '',
  description: '',
  startDate: '',
  dueDate: '',
  assignees: [],
  status: ProjectTaskStatus.Ready,
  predecessorIds: [],
  parentId: null
};

const isParent = (newParentPath: string | null | undefined, childPath: string | null | undefined) => {
  if (!newParentPath || !childPath) return false;
  return newParentPath.startsWith(childPath);
};

export const TaskFormDrawer: React.FC<Props> = ({ taskId, parentTaskId, ...drawerProps }) => {
  const defaultInputRef = useRef<HTMLDivElement>(null);
  const { notEmpty } = useValidationRules();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { getTaskName } = useTaskMessages();
  const { projectId } = useCurrentProject();
  const { principals } = useProjectPrincipals({ includeMe: true, usersOnly: true });

  const { control, handleSubmit, reset, formState: { isDirty } } = useForm<FormValues>({ defaultValues });
  const startDate = useWatch({ control, name: 'startDate' });

  const { data: task, isFetching } = useProjectTaskQuery({ taskId: taskId ?? 0, projectId }, { select: d => d.projectTask, enabled: !!taskId && drawerProps.open });

  const { data: parentTask, isFetching: isFetchingParentTask } = useProjectTaskQuery({ taskId: parentTaskId ?? 0, projectId }, { select: d => d.projectTask, enabled: !!parentTaskId && drawerProps.open });

  const { data: tasks } = useProjectTasksQuery({ projectId }, { select: d => d.projectTasks, enabled: !!taskId && drawerProps.open });


  const { data: currentParentTask, isFetching: isFetchingCurrentParentTask } = useParentTaskQuery({ taskId: taskId ?? 0, projectId }, {
    select: d => d.parentTask,
    meta: { nullable: true },
    enabled: !!taskId && drawerProps.open
  });

  useEffect(() => {

    if (task) {
      reset({
        name: getTaskName(task),
        description: task.description ?? '',
        startDate: task.startDate ? dayjs(task.startDate).format('YYYY-MM-DD') : '',
        dueDate: task.dueDate ? dayjs(task.dueDate).format('YYYY-MM-DD') : '',
        assignees: task.assignees.map<SecurityPrincipalShim>(a => ({ principalType: PrincipalType.User, id: a.id })),
        status: task.status,
        predecessorIds: task.predecessors.map(p => p.id),
        parentId: currentParentTask?.id || null
      });
    } else {
      reset({
        ...defaultValues,
        startDate: parentTask ? dayjs(parentTask.startDate).format('YYYY-MM-DD') : defaultValues.startDate,
        dueDate: parentTask ? dayjs(parentTask.startDate).add(7, 'days').format('YYYY-MM-DD') : defaultValues.dueDate,
        parentId: parentTaskId ?? null
      });
    }
    setTimeout(() => defaultInputRef.current?.focus(), 500);
  }, [reset, drawerProps.open, task, getTaskName, parentTaskId, parentTask, currentParentTask?.id, principals]);

  const { mutate: addTask, isLoading: isAddLoading } = useAddTaskMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      invalidateQuery(useMyTasksQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
      invalidateQuery(useProjectTaskSubtasksQuery, { taskId: parentTaskId });
    }
  });

  const { mutate: updateTask, isLoading: isUpdateLoading } = useUpdateTaskMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      invalidateQuery(useMyTasksQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectTaskQuery, { taskId: task?.id });
      invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
    }
  });

  const isLoading = isAddLoading || isUpdateLoading || isFetching || isFetchingParentTask;

  const onSubmit = (values: FormValues) => {
    if (task) {
      updateTask({
        input: {
          projectId,
          taskId: task.id,
          name: values.name,
          description: values.description,
          assigneeIds: values.assignees.map(p => p.id),
          startDate: dayjs(values.startDate).utc(),
          dueDate: dayjs(values.dueDate).utc(),
          status: values.status,
          predecessorIds: values.predecessorIds,
          parentTaskId: values.parentId
        }
      });
    } else {
      addTask({
        input: {
          projectId,
          name: values.name,
          description: values.description,
          assigneeIds: values.assignees.map(p => p.id),
          startDate: values.startDate == '' ? null : dayjs(values.startDate).utc(),
          dueDate: values.dueDate == '' ? null : dayjs(values.dueDate).utc(),
          status: values.status,
          predecessorIds: values.predecessorIds,
          parentTaskId: values.parentId
        }
      });
    }
  };

  const isEditingSystemTask = task != null && task.type !== ProjectTaskType.Custom;

  const getHeader = () => {
    if (task) {
      return formatMessage({ id: 'Update task' });
    }

    if (parentTaskId) {
      return formatMessage({ id: 'Add subtask' });
    }

    return formatMessage({ id: 'Add new task' });
  };

  return (
    <FormDrawer
      {...drawerProps}
      isFormDirty={isDirty}
      showFooter
      isSubmitting={isLoading}
      onSave={handleSubmit(onSubmit)}
      header={getHeader()}
    >
      <Stack gap={2} p={2}>
        {task != null && task.type !== ProjectTaskType.Custom &&
          <Alert severity='info'>{formatMessage({ id: 'This is a system task. Only the scheduling dates can be updated.' })}</Alert>
        }
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              required
              inputRef={defaultInputRef}
              {...field}
              disabled={isLoading || isEditingSystemTask}
              label={formatMessage({ id: 'Name' })}
              error={!!error}
              helperText={error?.message}
              autoComplete='off'
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field }) => (
            <TextField
              {...field}
              rows={3}
              multiline
              disabled={isLoading || isEditingSystemTask}
              label={formatMessage({ id: 'Description' })}
            />
          )}
        />

        <Stack direction='row' spacing={1} >
          <Controller
            control={control}
            name='startDate'
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                type='date'
                disabled={isLoading}
                label={formatMessage({ id: 'Start date' })}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Controller
            control={control}
            name='dueDate'
            rules={{
              validate: {
                afterStartDate: v => v == '' || (startDate != '' && dayjs(v).isAfter(dayjs(startDate))) || formatMessage({ id: 'Due date must be after start date' }),
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type='date'
                fullWidth
                disabled={isLoading}
                label={formatMessage({ id: 'Due date' })}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Stack>

        <Controller
          control={control}
          name='assignees'
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <PrincipalPicker
                multiple
                usersOnly
                includeMe
                value={field.value}
                onChange={field.onChange}
                label={formatMessage({ id: 'Assigned to' })}
                error={error?.message}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='status'
          render={({ field }) => (
            <TextField
              {...field}
              disabled={isLoading || isEditingSystemTask}
              label={formatMessage({ id: 'Status' })}
              select
              SelectProps={{
                renderValue: status => <TaskStatusDisplay status={status as ProjectTaskStatus} />
              }}
            >
              {_.orderBy(Object.values(ProjectTaskStatus), p => getTaskStatusOrderingKey(p)).map(status => (
                <MenuItem key={status} value={status}>
                  <TaskStatusDisplay status={status} />
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={control}
          name='predecessorIds'
          render={({ field }) => (
            <TaskPredecessorsPicker
              taskId={taskId}
              {...field}
              disabled={isLoading || isEditingSystemTask}
            />
          )}
        />

        <Controller
          control={control}
          name='parentId'
          rules={{
            validate: {
              isNotChild: parentId => {
                const parent = tasks?.find(t => t.id === parentId);
                if (isParent(parent?.hierarchyPath, task?.hierarchyPath)) {
                  return formatMessage({ id: 'Parent task cannot be a child of the task' });
                }
              }
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TaskSelectInput
              value={field.value}
              onChange={field.onChange}
              error={!!error}
              helperText={error?.message}
              disabled={!!parentTaskId || isFetchingCurrentParentTask || isLoading || isEditingSystemTask}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};