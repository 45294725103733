import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add, Delete, Edit } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { BudgetItemFragmentFragment, useBudgetItemsQuery, useDeleteBudgetItemMutation, useGetProjectBudgetQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  budgetItem: BudgetItemFragmentFragment;
  onEdit: () => void;
  onAddExpense: () => void;
}

export const BudgetItemActions: React.FC<Props> = ({ budgetItem, onEdit, onAddExpense }) => {
  const { formatMessage } = useIntl();
  const { projectId, canManageBudgetAndExpenses } = useCurrentProject();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const { mutate: deleteBudgetItem, isLoading } = useDeleteBudgetItemMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Budget item deleted successfully' }));
      invalidateQuery(useBudgetItemsQuery, { projectId });
      invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
    }
  });

  const onDelete = () => {
    deleteBudgetItem({
      input: {
        projectId,
        id: budgetItem.id,
      }
    });
  };

  if (!canManageBudgetAndExpenses) {
    return null;
  }

  return <>
    <ActionMenu>
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        {formatMessage({ id: 'Edit' })}
      </MenuItem>

      <MenuItem onClick={onAddExpense}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        {formatMessage({ id: 'Add expense' })}
      </MenuItem>

      <MenuItem onClick={() => setIsDeleting(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        {formatMessage({ id: 'Delete' })}
      </MenuItem>
    </ActionMenu>

    <ConfirmDialog
      open={isDeleting}
      onCancel={() => setIsDeleting(false)}
      onConfirm={onDelete}
      title={formatMessage({ id: 'Delete budget item' })}
      confirmColor='error'
      confirmText={formatMessage({ id: 'Delete' })}
      content={formatMessage({ id: 'Are you sure you want to delete this budget item? Related expenses will be unassigned.' })}
      loading={isLoading}
    />
  </>;
};