import { useGetMe } from '@utils/useGetMe';
import { isExternalUser } from '@utils/userUtils';
import { useMemo } from 'react';
import { PrincipalType, useGetProjectMembersQuery, useSecurityGroupsForProjectQuery } from '../../../gql';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { SecurityGroupPrincipal, SecurityPrincipal, UserPrincipal } from './types';

export type Props = {
  disabled?: boolean;
  usersOnly?: boolean;
  internalUsersOnly?: boolean;
  includeMe?: boolean;
};

export const useProjectPrincipals = ({ disabled, includeMe, usersOnly, internalUsersOnly }: Props) => {
  const { projectId } = useCurrentProject({ disabled });
  const { me } = useGetMe();

  const { data: projectUsers, isFetching: isFetchingUsers, } = useGetProjectMembersQuery({ projectId: projectId }, {
    select: p => p.projectMembers,
    enabled: !disabled
  });

  const { data: projectGroups, isFetching: isFetchingGroups } = useSecurityGroupsForProjectQuery({ projectId: projectId }, {
    select: p => p.securityGroupsForProject,
    enabled: !usersOnly && !disabled
  });

  const principals = useMemo(() => {
    const securityPrincipals: SecurityPrincipal[] = [];

    const userPrincipals = projectUsers
      ?.filter(p => !internalUsersOnly || !isExternalUser(p))
      .map<UserPrincipal>(p => ({
        ...p,
        principalType: PrincipalType.User,
      }) ?? []);

    userPrincipals?.forEach(p => securityPrincipals.push(p));

    const groupPrincipals = usersOnly ? [] : projectGroups?.map<SecurityGroupPrincipal>(p => ({
      ...p,
      principalType: PrincipalType.SecurityGroup,
    }) ?? []);

    groupPrincipals?.forEach(p => securityPrincipals.push(p));

    return includeMe ? securityPrincipals : securityPrincipals.filter(p => p.id !== me?.id);
  }, [includeMe, internalUsersOnly, me?.id, projectGroups, projectUsers, usersOnly]);

  return { principals, isFetching: isFetchingGroups || isFetchingUsers };
};