import { FormDrawer } from '@components/Drawers/FormDrawer';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker/PrincipalPicker';
import { DrawerProps, FormControlLabel, MenuItem, Link as MuiLink, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { PrincipalType, useAddExternalUserMutation, useAddProjectMemberMutation, useGetProjectMembersQuery, useGetProjectQuery, useGetProjectsQuery, useOrganizationUsersQuery, useSecurityGroupQuery, useSecurityGroupsForProjectQuery } from '../../../../gql';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useGetMe } from '../../../../utils/useGetMe';
import { getNormalizedPhoneNumber, isEmailValid, isPhoneNumberValid, useValidationRules } from '../../../../utils/useValidationRules';
import { useCurrentProject } from '../../utils/useCurrentProject';

type Props = Omit<DrawerProps, 'onSave'> & {
  userInput?: string;
  onInvitationComplete?: (id: string) => void;
};

type AddProjectMemberFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  business: string;
  phoneNumber: string;
  memberType: 'internal' | 'external';
  userId: string;
  securityGroupId: string;
  roleId: string;
};

const defaultValues: AddProjectMemberFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  business: '',
  phoneNumber: '',
  securityGroupId: '',
  memberType: 'internal',
  roleId: '',
  userId: ''
};

export const AddProjectMemberDrawer: React.FC<Props> = ({ userInput, onInvitationComplete, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { validateEmail, validatePhoneNumber } = useValidationRules();

  const form = useForm<AddProjectMemberFormValues>({ defaultValues });
  const { projectId } = useCurrentProject();
  const { reset, control, handleSubmit, formState: { isDirty } } = form;
  const { me, hasTenantAdminRole } = useGetMe();

  const { data: projectMembers } = useGetProjectMembersQuery({ projectId: projectId }, { select: d => d.projectMembers, enabled: drawerProps.open });
  const { data: allInternalUsers } = useOrganizationUsersQuery({}, { select: d => d.organizationUsers, enabled: drawerProps.open });

  const { data: securityGroupsData } = useSecurityGroupsForProjectQuery({ projectId }, { select: d => d.securityGroupsForProject, enabled: drawerProps.open });

  const memberType = useWatch({ control: control, name: 'memberType' });
  const userId = useWatch({ control: control, name: 'userId' });
  const securityGroups = useMemo(() => {
    return securityGroupsData?.filter(g => memberType === 'internal' || !g.isDefaultProjectAdminGroup);
  }, [securityGroupsData, memberType]);

  useEffect(() => {
    if (userInput) {
      reset({
        ...defaultValues,
        memberType: 'external',
        email: isEmailValid(userInput) ? userInput : '',
        phoneNumber: isPhoneNumberValid(userInput) ? userInput : '',
        firstName: !isEmailValid(userInput) && !isPhoneNumberValid(userInput) ? userInput : ''
      });
    } else {
      reset();
    }
  }, [reset, drawerProps.open, userInput]);

  const { mutate: inviteExternalUser, isLoading: isLoadingExternal } = useAddExternalUserMutation({
    onSuccess: (result, variables) => {
      notifySuccess(formatMessage({ id: 'User invited to project' }));
      invalidateQuery(useGetProjectQuery, { id: projectId });
      invalidateQuery(useGetProjectsQuery);
      invalidateQuery(useSecurityGroupsForProjectQuery);
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      invalidateQuery(useSecurityGroupQuery, { securityGroupId: variables.input.securityGroupId });
      drawerProps.onClose?.({}, 'backdropClick');
      const id = result.addExternalUser.applicationUser?.id;
      if (id) {
        onInvitationComplete?.(id);
      }
    }
  });

  const { mutate: addProjectMember, isLoading: isLoadingProjectMember } = useAddProjectMemberMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'User added to project' }));
      invalidateQuery(useGetProjectQuery, { id: projectId });
      invalidateQuery(useGetProjectsQuery);
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      invalidateQuery(useSecurityGroupQuery, { projectId: projectId });
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId: projectId });
      drawerProps.onClose?.({}, 'backdropClick');
    }
  });

  const onSubmit = (values: AddProjectMemberFormValues) => {
    if (values.memberType === 'external') {
      inviteExternalUser({
        input: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          projectId: projectId,
          securityGroupId: values.securityGroupId,
          business: values.business,
          phoneNumber: getNormalizedPhoneNumber(values.phoneNumber),
          title: values.title
        }
      });
    } else {
      addProjectMember({
        input: {
          projectId,
          securityGroupId: values.securityGroupId,
          userId: values.userId
        }
      });
    }
  };

  const isSubmitting = isLoadingExternal || isLoadingProjectMember;
  const disabled = isSubmitting;

  return (
    <FormDrawer {...drawerProps}
      disablePadding
      isSubmitting={isSubmitting}
      isFormDirty={isDirty}
      header={formatMessage({ id: 'Add a project member' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack component='form' noValidate autoComplete='off' gap={2} m={2}>
        <Controller
          control={control}
          name='memberType'
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel key={'internal'} value={'internal'} disabled={disabled || userInput != null} control={<Radio />} label={formatMessage({ id: 'Add an internal user' })} />
              <FormControlLabel key={'external'} value={'external'} disabled={disabled || userInput != null} control={<Radio />} label={formatMessage({ id: 'Invite an external user (client)' })} />
            </RadioGroup>
          )}
        />

        {memberType === 'internal' && <Stack>
          <Controller
            control={control}
            name='userId'
            render={({ field }) => (
              <PrincipalPicker
                usersOnly
                internalUsersOnly
                disabled={disabled}
                options={allInternalUsers?.map(p => ({ ...p, principalType: PrincipalType.User })) ?? []}
                filter={p => !projectMembers?.some(q => q.id === p.id)}
                label={formatMessage({ id: 'User' })}
                value={field.value ? [{ id: field.value, principalType: PrincipalType.User }] : []}
                onChange={principals => field.onChange(principals.at(0)?.id ?? '')}
              />
            )}
          />

          {hasTenantAdminRole && (
            <Stack direction={'row'} justifyContent={'right'}>
              <MuiLink component={Link} to={`/${getTenantIdentifier()}/users`}>{formatMessage({ id: 'Manage users' })}</MuiLink>
            </Stack>
          )}
        </Stack>}

        {memberType === 'external' &&
          <>
            <Controller
              control={control}
              name='email'
              rules={{
                required: formatMessage({ id: 'This field is required' }),
                validate: validateEmail
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={formatMessage({ id: 'Email' })}
                  disabled={disabled}
                  required
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name='firstName'
              rules={{ required: formatMessage({ id: 'This field is required' }) }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={formatMessage({ id: 'First name' })}
                  disabled={disabled}
                  required
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...field}
                  autoComplete='off'
                />
              )}
            />

            <Controller
              control={control}
              name='lastName'
              rules={{ required: formatMessage({ id: 'This field is required' }) }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={formatMessage({ id: 'Last name' })}
                  disabled={disabled}
                  required
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...field}
                  autoComplete='off'
                />
              )}
            />


            <Controller
              control={control}
              name='title'
              render={({ field }) => (
                <TextField
                  label={formatMessage({ id: 'Title' })}
                  disabled={disabled}
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name='business'
              render={({ field }) => (
                <TextField
                  label={formatMessage({ id: 'Business' })}
                  disabled={disabled}
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name='phoneNumber'
              rules={{
                validate: validatePhoneNumber
              }}
              render={({ field, fieldState: { error } }) => (
                <MuiTelInput
                  defaultCountry='CA'
                  flagSize="small"
                  forceCallingCode
                  disabled={disabled}
                  disableDropdown
                  label={formatMessage({ id: 'Phone number' })}
                  {...field}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          </>}

        <Controller
          control={control}
          name='securityGroupId'
          rules={{
            required: formatMessage({ id: 'This field is required' }),

          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              required
              SelectProps={{ MenuProps: { disablePortal: true } }}
              label={formatMessage({ id: 'Security group' })}
              {...field}
              disabled={disabled || me?.id == userId}
              error={Boolean(error)}
              helperText={error?.message}
            >
              {securityGroups?.map(securityGroup => (
                <MenuItem key={securityGroup.id} value={securityGroup.id}>
                  {securityGroup.name}
                </MenuItem>
              )) ?? []}
            </TextField>
          )}
        />
      </Stack>

    </FormDrawer>
  );
};