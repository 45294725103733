import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { useWorkflowInstancesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { StartWorkflowDialog } from '../components/StartWorkflowDialog/StartWorkflowDialog';
import { WorkflowInstancesTable } from '../components/WorflowInstance/WorkflowInstancesTable';

export const WorkflowsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [isAddingWorkflow, setIsAddingWorkflow] = useState(false);
  const { projectId, isArchived, canManageWorkflows } = useCurrentProject();

  const { data: workflows, isFetching } = useWorkflowInstancesQuery({ projectId }, {
    select: d => d.projectWorkflows
  });
  return (
    <PageContainer
      title={formatMessage({ id: 'Workflows' })}
      action={!isArchived && canManageWorkflows && (
        <ResponsiveButton icon={<Add />} variant='contained' onClick={() => setIsAddingWorkflow(true)}>
          {formatMessage({ id: 'Start a workflow' })}
        </ResponsiveButton>
      )}
    >
      <WorkflowInstancesTable workflows={workflows ?? []} loading={isFetching} />

      <StartWorkflowDialog open={isAddingWorkflow} onClose={() => setIsAddingWorkflow(false)} />
    </PageContainer>
  );
};