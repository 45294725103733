import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { WorkflowInstance, useDeleteWorkflowInstanceMutation, useWorkflowInstanceQuery, useWorkflowInstancesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  projectWorkflow: Pick<WorkflowInstance, 'id' | 'workFlowStatus'>;
}

export const WorkflowInstanceActions: React.FC<Props> = ({ projectWorkflow }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { projectId, isArchived } = useCurrentProject();

  const { mutate: deleteWorkflow, isLoading: isDeleting } = useDeleteWorkflowInstanceMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Workflow successfully deleted.' }));
      invalidateQuery(useWorkflowInstanceQuery, { projectId, workflowId: projectWorkflow.id });
      invalidateQuery(useWorkflowInstancesQuery, { projectId });
    }
  });

  const onDeleteConfirm = () => {
    deleteWorkflow({ id: projectWorkflow.id });
  };

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);

  return (
    <>
      <Stack direction='row' gap={2}>
        <ActionMenu open={moreMenuDrawerOpen} onOpen={setMoreMenuDrawerOpen}>

          <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)} disabled={isArchived}>
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Delete' })} />
          </MenuItem>
        </ActionMenu>
      </Stack>

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete workflow' })}
        content={formatMessage({ id: 'This workflow will be deleted. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isDeleting}
      />
    </>
  );
};
