import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSecurityGroupsForProjectQuery } from '../../../gql';
import { SecurityGroupFormDrawer } from '../components/SecurityGroupFormDrawer';
import { SecurityGroupsTable } from '../components/SecurityGroupsTable';

export const PermissionsGroupsView = () => {
  const { projectId } = useCurrentProject();
  const { formatMessage } = useIntl();

  const { data: projectSecurityGroups, isFetching } = useSecurityGroupsForProjectQuery({ projectId }, { select: d => d.securityGroupsForProject });

  const [isAddSecurityGroupDrawerOpen, setIsAddSecurityGroupDrawerOpen] = useState(false);

  return (
    <PageContainer
      title={formatMessage({ id: 'Groups' })}
      action={(
        <ResponsiveButton icon={<Add />} variant='contained' onClick={() => setIsAddSecurityGroupDrawerOpen(true)}>
          {formatMessage({ id: 'Add group' })}
        </ResponsiveButton>
      )}
    >
      <Stack gap={2}>
        <SecurityGroupsTable projectId={projectId} securityGroups={projectSecurityGroups ?? []} loading={isFetching} />

        <SecurityGroupFormDrawer
          open={isAddSecurityGroupDrawerOpen}
          onClose={() => setIsAddSecurityGroupDrawerOpen(false)}
        />
      </Stack>
    </PageContainer>
  );
};