import { FormFiller } from '@modules/forms/components/FormFiller';
import { FormViewer } from '@modules/forms/components/FormViewer';
import { CircularProgress, Container, Stack } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { SubmissionStatus, useProjectFormSubmissionQuery, useProjectTaskQuery, useProjectTasksQuery, useSubmitWorkflowActionMutation } from 'gql/index';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';

export const WorkflowFormFillerView: React.FC = () => {
  const { workflowId: workflowIdString, actionId: actionIdString, submissionId: submissionIdString } = useParams();
  const { projectId } = useCurrentProject();
  const selectedSubmissionId = submissionIdString ? Number(submissionIdString) : undefined;

  const workflowId = Number(workflowIdString);
  const actionId = Number(actionIdString);
  const navigate = useNavigate();
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const { data: selectedSubmissionData } = useProjectFormSubmissionQuery({ submissionId: selectedSubmissionId ?? -1 }, {
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: false,
    select: d => d.projectFormSubmission,
    enabled: !!selectedSubmissionId
  });

  const selectedSubmission = selectedSubmissionId ? selectedSubmissionData : undefined;

  const { mutate: submitWorkflowAction, isLoading: isSubmitting } = useSubmitWorkflowActionMutation({
    onSuccess: () => {
      invalidateQuery(useProjectTaskQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormSubmissionQuery, { submissionId: selectedSubmissionId ?? -1 });

      navigate(`/${getTenantIdentifier()}/projects/${projectId}/home`);

      notifySuccess(formatMessage({ id: 'Form successfully submitted.' }));
    }
  });

  const onSubmit = () => {
    if (selectedSubmissionId == null) return;

    submitWorkflowAction({
      input: {
        workflowActionId: actionId,
        workflowInstanceId: workflowId
      }
    });
  };

  if (!selectedSubmission || !selectedSubmission.formDefinition) {
    return (
      <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  if (selectedSubmission.status === SubmissionStatus.IsResponding) {
    return (
      <FormFiller
        disabled={isSubmitting}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        values={selectedSubmission.formValues}
        formDefinition={selectedSubmission.formDefinition}
        submissionId={selectedSubmission.id}
      />
    );
  } else {
    return (
      <Container maxWidth='xl' sx={{ px: 1, py: 2 }}>
        <FormViewer
          definition={selectedSubmission.formDefinition}
          values={selectedSubmission.formValues}
        />
      </Container>
    );
  }
};