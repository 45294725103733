import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, ListItemText } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { MeetingTypesQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { MeetingTypeActions } from './MeetingTypeActions';

type MeetingType = MeetingTypesQuery['meetingTypes'][0];

interface Props {
  meetingTypes: MeetingType[];
}

export const MeetingTypesTable: React.FC<Props> = ({ meetingTypes }) => {
  const { formatMessage, formatDate } = useIntl();

  const columns: GridColDef<MeetingType>[] = [
    {
      field: 'name',
      flex: 2,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) => (
        <ListItemText
          sx={{ p: 2 }}
          primary={row.name}
          secondary={row.description}
        />
      )
    },
    {
      field: 'duration',
      flex: 1,
      minWidth: 100,
      headerName: formatMessage({ id: 'Duration' }),
      valueGetter: ({ row }) => dayjs.duration(row.duration).humanize()
    },
    {
      field: 'createdOn',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created on' }),
      valueGetter: ({ row }) => row.createdOn,
      valueFormatter: ({ value }) => formatDate(value)
    },
    {
      field: 'participants',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Participants' }),
      valueGetter: ({ row }) => row.meetingRequestsOwners,
      renderCell: ({ row }) => (
        <AvatarGroup>
          {row.meetingRequestsOwners.map(user => (
            <UserAvatar key={user.fullName} displayName={user.fullName} />
          ))}
        </AvatarGroup>
      )
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => (
        <MeetingTypeActions meetingType={row} />
      )
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      columns={columns}
      rows={meetingTypes ?? []}
      noDataMessage={formatMessage({ id: 'No meeting types have been created yet' })}
    />
  );
};