import { Property } from '@components/DataDisplay/Property';
import { PageContainer } from '@components/Layout/PageContainer';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Close, Done } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AvatarGroup, CircularProgress, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { dateRangesOverlap } from '@utils/dateUtils';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { Availability, MeetingStatus, useAcceptMeetingRequestMutation, useCalendarAvailabilitiesQuery, useMeetingQuery } from 'gql/index';
import { max, min } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { MeetingActions } from '../components/MeetingActions';
import { MeetingDeclineDialog } from '../components/MeetingDeclineDialog';
import { MeetingProposalPageSection } from '../components/MeetingProposalSection';


export type RespondToMeetingFormValues = {
  projectId: number,
  selectedProposedTimeId: number | undefined,
  declineReason: string | undefined;
};

export const MeetingPage = () => {
  const { meetingId: meetingIdString } = useParams();
  const meetingId = Number(meetingIdString);
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();
  const { me } = useGetMe();


  const { data: meeting } = useMeetingQuery({ meetingId, projectId }, { select: d => d.meeting });
  const meetingDurationMinutes = meeting?.meetingType?.duration ? dayjs.duration(meeting?.meetingType?.duration).asMinutes() : undefined;

  const form = useForm<RespondToMeetingFormValues>();
  const selectedOptionId = useWatch({ control: form.control, name: 'selectedProposedTimeId' });
  const isMeetingTypeManager = meeting?.meetingType?.meetingRequestsOwners.some(p => p.id === me?.id);

  const canRespondToMeetingRequest = meeting?.meetingStatus == MeetingStatus.Requested && isMeetingTypeManager;

  const hasResponded = meeting?.proposals.flatMap(p => p.acceptedUsers).concat(meeting?.proposals.flatMap(p => p.declinedUsers)).some(u => u.id === me?.id);

  const { mutate: acceptMeeting, isLoading: isAcceptingMeeting } = useAcceptMeetingRequestMutation({
    onSuccess: () => {
      invalidateQuery(useMeetingQuery, { meetingId: meetingId });
      notifySuccess(formatMessage({ id: 'Meeting accepted successfully.' }));
    }
  });

  const isResponding = isAcceptingMeeting;

  const onAccept = (values: RespondToMeetingFormValues) => {
    if (values.selectedProposedTimeId != null) {
      acceptMeeting({
        input: {
          projectId,
          meetingId: meetingId,
          proposalId: values.selectedProposedTimeId,
        }
      });
    }
  };

  const [isDeclineDialogOpen, setDeclineDialogOpen] = useState(false);

  const allMeetingTimes = meeting?.proposals.map(p => dayjs(p.startTime).toISOString());

  const earliestStart = allMeetingTimes ? dayjs(min(allMeetingTimes)) : undefined;
  const latestStart = allMeetingTimes ? dayjs(max(allMeetingTimes)) : undefined;

  const { data: availabilities } = useCalendarAvailabilitiesQuery({
    input: {
      memberId: me?.id ?? '',
      startTime: earliestStart?.startOf('day').toISOString(),
      endTime: latestStart?.endOf('day').toISOString(),
      projectId,
      timeIntervalInMinutes: meetingDurationMinutes!,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }, {
    enabled: Boolean(earliestStart && latestStart && meetingDurationMinutes),
    select: d => d.calendarAvailabilities
  });

  if (!meeting) {
    return (
      <Stack alignItems='center' justifyContent='center' width='100%' height='100%'>
        <CircularProgress />
      </Stack>
    );
  }

  return <PageContainer
    title={meeting.name}
    subtitle={meeting.description}
    action={(
      <MeetingActions
        meeting={meeting}
        variant='squareIcon'
        buttonProps={{ variant: 'contained' }}
      />
    )}
  >
    <FormProvider {...form}>
      <Stack gap={2} mt={1} mb={4}>
        <PageSection title={formatMessage({ id: 'Details' })}>
          <PageSectionPart gap={2}>
            {meeting.startTime && <>
              <Property propertyName={formatMessage({ id: 'Start time' })}>
                <ListItem>
                  <ListItemText primary={dayjs(meeting.startTime).format('LLL')} />
                </ListItem>
              </Property>
              <Property propertyName={formatMessage({ id: 'End time' })}>
                <ListItem>

                  <ListItemText primary={dayjs(meeting.endTime).format('LLL')} />
                </ListItem>
              </Property>
            </>}
            <Property propertyName={formatMessage({ id: 'Duration' })}>
              <ListItem>
                <ListItemText primary={dayjs.duration(meeting.meetingType?.duration).humanize()} />
              </ListItem>
            </Property>
            <Property propertyName={formatMessage({ id: 'Requested by' })}>
              <ListItem>
                <ListItemIcon>
                  <UserAvatar displayName={meeting.createdBy?.fullName} />
                </ListItemIcon>
                <ListItemText primary={meeting.createdBy?.fullName} />
              </ListItem>
            </Property>
            {meeting.meetingStatus == MeetingStatus.Planned &&
              <Property propertyName={formatMessage({ id: 'Participants' })}>
                <ListItem>
                  <ListItemIcon>
                    <AvatarGroup>
                      {meeting.participants.map(p => <UserAvatar key={p.id} displayName={p.fullName} />)}
                    </AvatarGroup>
                  </ListItemIcon>

                </ListItem>
              </Property>

            }

          </PageSectionPart>
        </PageSection>

        {meeting.meetingStatus == MeetingStatus.Requested &&
          <PageSection title={formatMessage({ id: 'Proposed times' })}>
            {meeting.proposals.map(proposal => {
              const proposalEndTime = dayjs(proposal.startTime).add(meetingDurationMinutes ?? 0, 'minutes');
              const overlappingAvailabilities = availabilities?.availableStartTimes
                .filter(s => dateRangesOverlap(dayjs(proposal.startTime).toDate(), proposalEndTime.toDate(), dayjs(s.startTime).toDate(), dayjs(s.startTime).add(meetingDurationMinutes ?? 0, 'minutes').toDate())) ?? [];

              return (
                <MeetingProposalPageSection
                  key={proposal.id}
                  meeting={meeting}
                  proposal={proposal}

                  availableAtThisTime={overlappingAvailabilities?.length > 0 && overlappingAvailabilities?.every(p => p.availability === Availability.Free)}
                  conflictExists={overlappingAvailabilities.some(p => p.availability !== Availability.Free)}
                />
              );
            })}
          </PageSection>
        }

        {canRespondToMeetingRequest && !hasResponded &&
          <Stack flexGrow={1} direction='row' gap={2} justifyContent='end' alignItems='end' flexWrap='wrap'>
            <LoadingButton variant='contained' color='error' startIcon={<Close />} onClick={() => setDeclineDialogOpen(true)}>
              {formatMessage({ id: 'Decline' })}
            </LoadingButton>

            <DisableableTooltip disabled={!isResponding && selectedOptionId != null} title={formatMessage({ id: 'Select a proposed time before accepting the meeting.' })}>
              <LoadingButton disabled={isResponding || selectedOptionId == null} loading={isAcceptingMeeting} variant='contained' color='primary' startIcon={<Done />} onClick={form.handleSubmit(onAccept)}>
                {formatMessage({ id: 'Accept' })}
              </LoadingButton>
            </DisableableTooltip>
          </Stack>
        }
      </Stack>
    </FormProvider>

    <MeetingDeclineDialog
      meeting={meeting}
      open={isDeclineDialogOpen}
      onCancel={() => setDeclineDialogOpen(false)}
    />
  </PageContainer>;
};