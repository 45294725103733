import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Breadcrumbs, Link, Stack } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { FolderType, useProjectFoldersQuery } from 'gql/index';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getFolderNameByType } from '../utils/folderUtils';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';



export const FolderTableBreadcrumbs = () => {
  const { formatMessage } = useIntl();

  const { breadcrumbPath, setBreadcrumbPath } = useContext(ProjectDocumentsContext);

  const { projectId } = useCurrentProject();
  const { data: projectFolders } = useProjectFoldersQuery({ projectId }, { select: d => d.projectFolders });
  const breadcrumbs = breadcrumbPath.map(id => projectFolders?.find(f => f.id === id)).filter(notNullOrUndefined);

  const isCurrentlyRoot = breadcrumbPath.length === 0;

  return (
    <Breadcrumbs sx={{ userSelect: 'none' }}>
      <Link
        color={breadcrumbPath.length === 0 ? 'text.primary' : 'inherit'}
        underline='hover'
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={() => setBreadcrumbPath([])}
      >
        <Stack mr={1}>
          {isCurrentlyRoot ? (
            <FontAwesomeIcon icon={faFolder} color='orange' />
          ) : (
            <FontAwesomeIcon icon={faFolderOpen} />
          )}
        </Stack>

        {formatMessage({ id: 'Documents' })}
      </Link>

      {breadcrumbs.map((folder, index) => (
        <Link
          key={folder.id}
          color={index == breadcrumbPath.length - 1 ? 'text.primary' : 'inherit'}
          underline='hover'
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => setBreadcrumbPath(breadcrumbPath.filter((_, index) => index <= breadcrumbPath.indexOf(folder.id)))}
        >
          <Stack mr={1}>
            {index == breadcrumbPath.length - 1 ? (
              <FontAwesomeIcon icon={faFolder} color='orange' />
            ) : (
              <FontAwesomeIcon icon={faFolderOpen} />
            )}
          </Stack>

          {folder.folderType === FolderType.Custom ? folder.name : formatMessage(getFolderNameByType[folder.folderType])}
        </Link>
      ))}
    </Breadcrumbs>
  );
};