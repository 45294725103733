import { ActionMenu } from '@components/ActionMenu';
import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { TaskImportDrawer } from '@modules/projects/components/TaskImportDrawer/TaskImportDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import { MenuItem, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useProjectTasksQuery } from 'gql/index';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { useQueryInvalidator } from '../../../utils/useQueryInvalidator';
import { useResponsive } from '../../../utils/useResponsive';
import { tasksViewState } from '../atoms';
import { TasksGanttView } from '../components/Gantt/TasksGanttView';
import { TaskFormDrawer } from '../components/TaskFormDrawer';
import { TasksTable } from '../components/TasksTable';

export const ProjectTasksView = () => {
  const { formatMessage } = useIntl();

  const { isMobile } = useResponsive();
  const { projectId, canManageTasks, canViewTasks, isArchived } = useCurrentProject();
  const [viewMode, setViewMode] = useRecoilState(tasksViewState);
  const invalidateQuery = useQueryInvalidator();
  const { data: tasks, isFetching } = useProjectTasksQuery({ projectId }, {
    select: d => d.projectTasks,
    staleTime: 120000
  });

  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isImportingTasks, setIsImportingTasks] = useState(false);

  useEffect(() => {
    if (!canViewTasks) {
      setViewMode('list');
    }
    invalidateQuery(useProjectTasksQuery);
  }, [viewMode, setViewMode, invalidateQuery, canViewTasks]);

  return <>
    <PageContainer
      title={formatMessage({ id: 'Tasks' })}
      action={canManageTasks && !isArchived && (
        <Stack direction='row' alignItems='center' gap={2}>
          <ResponsiveButton variant='contained' icon={<Add />} onClick={() => setIsAddingTask(true)}>
            {formatMessage({ id: 'Add task' })}
          </ResponsiveButton>

          <ActionMenu variant='squareIcon' buttonProps={{ variant: 'contained' }}>
            <MenuItem onClick={() => setIsImportingTasks(true)}>
              {formatMessage({ id: 'Import tasks' })}
            </MenuItem>
          </ActionMenu>
        </Stack>
      )}
    >
      <Stack flex={1} gap={2} height={viewMode === 'gantt' ? '100%' : undefined}>
        {!isMobile && canViewTasks &&
          <ToggleButtonGroup
            color="primary"
            value={isMobile ? 'list' : viewMode}
            exclusive
            onChange={(_e, value) => {
              if (!value) return;
              setViewMode(value);
            }}
          >
            <ToggleButton value="list" >
              <ListIcon sx={{ mr: 1 }} />
              {formatMessage({ id: 'list' })}
            </ToggleButton>
            <ToggleButton value="gantt">
              <WaterfallChartIcon sx={{ transform: 'rotate(-90deg)', mr: 1 }} />
              {formatMessage({ id: 'gantt' })}
            </ToggleButton>
          </ToggleButtonGroup>
        }


        {viewMode !== 'gantt' && (

          <TasksTable tasks={tasks ?? []} loading={isFetching} flex={true} />

        )}

        {viewMode === 'gantt' && (
          <Stack height='100%' width='100%'>
            <TasksGanttView tasks={tasks?.filter(p => p.startDate && p.dueDate) ?? []} loading={isFetching} readonly={!canManageTasks || isArchived} />
          </Stack>
        )}

        <TaskFormDrawer
          open={isAddingTask}
          onClose={() => setIsAddingTask(false)}
        />

        <TaskImportDrawer
          open={isImportingTasks}
          onClose={() => setIsImportingTasks(false)}
        />
      </Stack>
    </PageContainer >
  </>;
};