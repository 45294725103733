
const hierarchyPathGetAncestor = (hierarchyPath: string) => hierarchyPath.split('/').slice(0, -2).join('/') + '/';

interface Data {
  id: number
  hierarchyPath?: string | null
}

export const treeSelectionUpdated = (selectedIds: number[], previousSelectedIds: number[], data: Data[], onChange: (...event: any[]) => void) => {

  const deselectedIds = previousSelectedIds.filter(id => !selectedIds.includes(id));

  for (const removedId of deselectedIds) {
    const removedHierarchyPath = data?.find(d => d.id === removedId)?.hierarchyPath;

    if (!removedHierarchyPath) continue;

    const childIds = data?.filter(d => d.hierarchyPath?.startsWith(removedHierarchyPath)).map(d => d.id) ?? [];

    selectedIds = selectedIds.filter(id => !childIds.includes(id));
  }

  const newlySelectedIds = selectedIds.filter(id => !previousSelectedIds.includes(id));

  for (const newlySelectedId of newlySelectedIds) {
    const newlySelectedHierarchyPath = data?.find(d => d.id === newlySelectedId)?.hierarchyPath;

    if (!newlySelectedHierarchyPath) continue;

    const childIds = data?.filter(d => d.hierarchyPath?.startsWith(newlySelectedHierarchyPath)).map(d => d.id) ?? [];

    selectedIds = selectedIds.concat(childIds);
  }

  for (const Id of selectedIds) {
    const task = data?.find(d => d.id === Id);

    let hierarchyPathAncestor = hierarchyPathGetAncestor(task?.hierarchyPath ?? '/');

    while (hierarchyPathAncestor !== '/') {
      const ancestor = data?.find(d => d.hierarchyPath === hierarchyPathAncestor);

      if (ancestor && !selectedIds.includes(ancestor.id)) {
        selectedIds.push(ancestor.id);
      }

      hierarchyPathAncestor = hierarchyPathGetAncestor(hierarchyPathAncestor);
    }
  }

  const dedup = [...new Set(selectedIds)];

  onChange(dedup);
};
