import { PropertyGroup } from '@components/DataDisplay/PropertyGroup';
import { PropertyGroupSkeleton } from '@components/DataDisplay/PropertyGroupSkeleton';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { getFolderNameByType } from '@modules/folders/utils/folderUtils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Paper } from '@mui/material';
import { FolderType, useFolderPropertiesQuery } from 'gql/index';
import { useIntl } from 'react-intl';

interface Props {
  folderId: number;
}

export const FolderBasicProperties: React.FC<Props> = ({ folderId }) => {
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();

  const { data: folder, isFetching } = useFolderPropertiesQuery({ folderId, projectId }, { select: d => d.folder });
  const documentsCount = folder?.entries.length ?? 0;

  if (isFetching) {
    return (
      <Paper sx={{ p: 2 }}>
        <PropertyGroupSkeleton propertiesCount={1} />
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <PropertyGroup title={formatMessage({ id: 'Properties' })}>

        <TextProperty propertyName={formatMessage({ id: 'Name' })} propertyValue={folder ? (folder.folderType === FolderType.Custom ? folder.name : formatMessage(getFolderNameByType[folder.folderType])) : ''} />

        <TextProperty propertyName={formatMessage({ id: 'File count' })} propertyValue={documentsCount.toString()} />
      </PropertyGroup>
    </Paper>
  );
};