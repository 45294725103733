import { UploadManagerContext } from '@components/FileUpload/UploadManagerContext';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useCallback, useContext } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useNotification } from '../../../../../../utils/useNotification';
import { AnonymousContext } from '../../../../../projectAccessTokens/AnonymousContextProvider';
import { FormAttachment } from '../../types';

interface Props {
  formSubmissionId: number;
  fieldId: number;
  disabled: boolean;
  allowMultipleFiles: boolean;
  uploadBatchId: string;
  onFileUploaded?: (formAttachment: FormAttachment) => void;
}

export const useFormFieldUploadDropzone = ({
  formSubmissionId, fieldId, disabled, allowMultipleFiles, uploadBatchId, onFileUploaded

}: Props) => {

  const { accessToken } = useContext(AnonymousContext);
  const { queueUpload } = useContext(UploadManagerContext);
  const { notifyFailure } = useNotification();
  const { formatMessage } = useIntl();

  const onUploadSuccess = useCallback((response: unknown, fileName: string) => {
    if (typeof response !== 'string') {
      return;
    }
    const attachmentUploadResponse = JSON.parse(response) as { attachmentId?: string; };
    if (attachmentUploadResponse?.attachmentId && !isNaN(Number(attachmentUploadResponse.attachmentId))) {
      onFileUploaded?.({
        id: Number(attachmentUploadResponse.attachmentId),
        fileName: fileName
      });
    }
  }, [onFileUploaded]);

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const path = `${formSubmissionId}/${fieldId}`;


    for (const file of acceptedFiles) {
      const extensionIndex = file.name.lastIndexOf('.');
      const hasExtension = extensionIndex > -1;

      const fileName = hasExtension ? file.name.substring(0, extensionIndex) : file.name;
      const fileExtension = hasExtension ? file.name.substring(extensionIndex, file.name.length) : '';

      const formData = new FormData();

      formData.append('File', file);
      formData.append('FileName', fileName + fileExtension);
      formData.append('formSubmissionId', formSubmissionId.toString());
      formData.append('FieldId', fieldId.toString());

      if (accessToken != null) {
        formData.append('AccessToken', accessToken);
      }

      const openRequest = () => {
        const request = new XMLHttpRequest();
        if (accessToken != null) {

          request.open('POST', `/${getTenantIdentifier()}/api/Form/Submission/${formSubmissionId}/AttachmentsPAT`);
        } else {
          request.open('POST', `/${getTenantIdentifier()}/api/Form/Submission/${formSubmissionId}/Attachments`);
        }

        return request;
      };

      queueUpload(uploadBatchId,
        {
          fileName,
          formData,
          onSuccess: (response) => onUploadSuccess(response, file.name),
          path,
          openRequest
        });
    }
  }, [formSubmissionId, fieldId, accessToken, queueUpload, uploadBatchId, onUploadSuccess]);

  const dropZone = useDropzone({

    noClick: false,
    noKeyboard: true,
    multiple: allowMultipleFiles,
    maxSize: 10000000,
    onDropAccepted,
    onDropRejected: (rejections) => {
      if (rejections.flatMap(p => p.errors).some(p => p.code === ErrorCode.FileTooLarge)) {
        notifyFailure(formatMessage({ id: 'The selected file is too large.' }));
      }
    },
    disabled: disabled
  });


  return { dropZone };
};