import { CircularProgress, Container, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  title?: string;
  subtitle?: string | null;
  action?: ReactNode;
  children: React.ReactNode | React.ReactNode[];
  disableGutters?: boolean;
  fullPageLoading?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const PageContainer: React.FC<Props> = ({ children, action, title, subtitle, disableGutters, fullPageLoading, maxWidth }) => {

  if (fullPageLoading) {
    return <>
      <Stack alignItems='center' justifyContent='center' height='100%' width='100%'>
        <CircularProgress />
      </Stack>
    </>;
  }

  return (
    <Stack height={'100%'} overflow={'auto'}>
      <Container maxWidth={maxWidth ?? 'xl'} disableGutters={disableGutters} sx={{ py: disableGutters ? 0 : 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
        {title && (
          <Stack width='100%' direction='row' alignItems='center' justifyContent='space-between' pb={2}>
            <Stack gap={1}>
              <Typography variant='h5'>{title}</Typography>

              {subtitle && (
                <Typography variant='subtitle1'>
                  {subtitle}
                </Typography>
              )}
            </Stack>

            {action}
          </Stack>
        )}

        {children}
      </Container>
    </Stack>
  );
};