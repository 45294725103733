import { CopyField } from '@components/DataDisplay/CopyField';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useRoleAssignment } from '@modules/forms/utils/useRoleAssignment';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Box, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, Stack, Tab, Tabs, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ShareableResourceType, useDocumentPermissionsQuery, useFolderEntriesQuery, useGetDocumentQuery, useProjectDocumentsQuery, usePublishDocumentMutation, useUnpublishDocumentMutation } from '../../../../../gql';
import { userRoleMessages } from '../../../../application/messages';
import { RoleAssignmentActions } from '../../Permissions/RoleAssignmentActions';
import { RoleAssignmentListItem } from '../../Permissions/RoleAssignmentListItem';
import { ShareableResourceItem } from '../../Permissions/ShareableResourceItem';
import { ShareableResource } from '../../Permissions/types';
import { PermissionsModalContext } from '../PermissionsModal';


interface Props {
  resource: ShareableResource;
}

export const ManageAccessView: React.FC<Props> = ({ resource }) => {
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { canManageDocuments, canManageDocumentPublicLinkSharing } = useCurrentProject();
  const { userAssignments, groupAssignments, sharedWithAllAssignment } = useRoleAssignment(resource);
  const { formatMessage } = useIntl();

  const groupAssignmentsCount = groupAssignments.length + (sharedWithAllAssignment?.sharedWithAllProjectMembers ? 1 : 0);

  const { goToAccessSummary, goToGrantAccess } = useContext(PermissionsModalContext);
  const [activeTab, setActiveTab] = useState<'users' | 'groups'>('users');

  const isForm = resource.resourceType === ShareableResourceType.Form;

  const { mutate: publishDocument, isLoading: isPublishing } = usePublishDocumentMutation({
    onSuccess: () => {
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useDocumentPermissionsQuery, { documentId: resource.id });
      invalidateQuery(useGetDocumentQuery, { id: resource.id });
      invalidateQuery(useFolderEntriesQuery);
    }
  });

  const onPublishDocument = () => {
    publishDocument({
      input: {
        documentId: resource.id,
        projectId
      }
    });
  };

  const { mutate: unpublishDocument, isLoading: isUnpublishing } = useUnpublishDocumentMutation({
    onSuccess: () => {
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useDocumentPermissionsQuery, { documentId: resource.id });
      invalidateQuery(useGetDocumentQuery, { id: resource.id });
      invalidateQuery(useFolderEntriesQuery);
    }
  });

  const onUnpublishDocument = () => {
    unpublishDocument({
      input: {
        documentId: resource.id,
        projectId
      }
    });
  };

  const publishLoading = isPublishing || isUnpublishing;

  const CheckboxSizedCircularProgress = () => (
    <CircularProgress size={'22px'} sx={{ m: '10px' }} />
  );

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h5'>{formatMessage({ id: 'Manage' })}</Typography>
        {!sharedWithAllAssignment?.sharedWithAllProjectMembers && canManageDocuments &&
          <DisableableTooltip fullWidth title={formatMessage({ id: 'Forms can only be shared when publishing.' })} disabled={!isForm}>
            <Button variant='contained' size='small' onClick={goToGrantAccess} disabled={isForm}>
              {formatMessage({ id: 'Grant Access' })}
            </Button>
          </DisableableTooltip>
        }
      </Stack>

      <ShareableResourceItem resource={resource} />

      {resource.resourceType === ShareableResourceType.Document && canManageDocumentPublicLinkSharing && <>
        <FormControl>
          <FormControlLabel
            label={formatMessage({ id: 'Share with public link' })}
            disabled={publishLoading}
            control={publishLoading ? (
              <CheckboxSizedCircularProgress />
            ) : (
              <Checkbox
                checked={resource.publicSlug != null}
                onChange={(_, checked) => checked ? onPublishDocument() : onUnpublishDocument()}
              />
            )}
          />
        </FormControl>

        {resource.publicSlug && (
          <CopyField
            value={`${window.location.origin}/${getTenantIdentifier()}/shared/${resource.publicSlug}`}
            label={formatMessage({ id: 'Publicly accessible link' })}
          />
        )}

      </>}

      {canManageDocuments && <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
            <Tab value='users' label={`${formatMessage({ id: 'Users' })} ${userAssignments.length > 0 ? `(${userAssignments.length})` : ''}`} />
            <Tab value='groups' label={`${formatMessage({ id: 'Groups' })} ${groupAssignmentsCount > 0 ? `(${groupAssignmentsCount})` : ''}`} />
          </Tabs>
        </Box>

        {activeTab === 'users' && (
          <Box>
            {userAssignments.map(assignment => (
              <RoleAssignmentListItem
                key={assignment.id}
                onClick={() => goToAccessSummary(assignment)}
                roleAssignment={assignment}
                secondaryAction={(
                  <Stack direction='row' gap={1} alignItems='center'>
                    <RoleAssignmentActions resource={resource} roleAssignment={assignment} />
                    <Chip variant='outlined' color='info' label={assignment.applicationRole && formatMessage(userRoleMessages[assignment.applicationRole.builtInRole])} />
                  </Stack>
                )}
              />
            ))}

            {userAssignments.length === 0 && (
              <Typography align='center'>
                {formatMessage({ id: 'No users with access' })}
              </Typography>
            )}
          </Box>
        )}

        {activeTab === 'groups' && (
          <Box>
            {sharedWithAllAssignment?.sharedWithAllProjectMembers &&
              <>
                <RoleAssignmentListItem
                  key={sharedWithAllAssignment.id}
                  roleAssignment={sharedWithAllAssignment}
                  secondaryAction={(
                    <Stack direction='row' gap={1} alignItems='center'>
                      <RoleAssignmentActions resource={resource} roleAssignment={sharedWithAllAssignment} />
                      <Chip variant='outlined' color='info' label={sharedWithAllAssignment.applicationRole && formatMessage(userRoleMessages[sharedWithAllAssignment.applicationRole.builtInRole])} />
                    </Stack>
                  )}
                />
              </>}
            {groupAssignments.map(assignment => (
              <RoleAssignmentListItem
                key={assignment.id}
                onClick={() => goToAccessSummary(assignment)}
                roleAssignment={assignment}
                secondaryAction={(
                  <Stack direction='row' gap={1} alignItems='center'>
                    <RoleAssignmentActions resource={resource} roleAssignment={assignment} />
                    <Chip variant='outlined' color='info' label={assignment.applicationRole && formatMessage(userRoleMessages[assignment.applicationRole.builtInRole])} />
                  </Stack>
                )}
              />
            ))}

          </Box>
        )}
      </>
      }

    </Stack>);
};