import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { SignaturePadPaper } from '@components/SignaturePadPaper';
import { ProjectDocumentsContext } from '@modules/folders/components/ProjectDocumentsContextProvider';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useResponsive } from '@utils/useResponsive';
import { useFolderEntriesQuery, useMyTasksQuery, useProjectDocumentsQuery, useProjectTaskQuery, useProjectTasksQuery, useSignDocumentMutation, useTenantSettingsQuery, useWorkflowInstancesQuery } from 'gql/index';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ZohoSignaturePad } from './ZohoSignaturePad';

interface OwnProps extends DialogProps {
  documentId: number;
  requestId?: string;
  actionId?: string;
  onSuccess?: () => void;
}

export const SignatureDialog: React.FC<OwnProps> = ({ documentId, requestId, actionId, onSuccess, ...dialogProps }) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { currentFolderId } = useContext(ProjectDocumentsContext);

  const { projectId } = useCurrentProject();
  const { data: isSignDocumentByZohoEnabled } = useTenantSettingsQuery(undefined, { select: d => d.tenantSettings.isSignDocumentByZohoEnabled });

  const signDocumentMutation = useSignDocumentMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Document signed successfully.' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useProjectTaskQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: currentFolderId });
      invalidateQuery(useWorkflowInstancesQuery, { projectId });
      invalidateQuery(useMyTasksQuery, { projectId });
      dialogProps.onClose?.({}, 'backdropClick');
      onSuccess?.();
    }
  });

  const onDrawnSignature = (signature: string) => {
    const signatureBase64String = signature.split(',').at(1);

    if (!signatureBase64String || !documentId) return;

    signDocumentMutation.mutate({
      input: {
        projectId: projectId,
        documentId: documentId,
        signatureBase64String
      }
    });
  };

  const onTextualSignature = (signature: string) => {
    if (!signature || !documentId) return;

    signDocumentMutation.mutate({
      input: {
        projectId: projectId,
        documentId: documentId,
        textSignature: signature
      }
    });
  };

  return (
    <ResponsiveDialog
      maxWidth={isSignDocumentByZohoEnabled ? 'xl' : 'md'}
      title={isMobile ? formatMessage({ id: 'Sign document' }) : undefined}
      PaperProps={{ sx: { height: '100%', maxHeight: 700 } }}
      {...dialogProps}
    >
      {isSignDocumentByZohoEnabled && actionId && requestId ? (
        <ZohoSignaturePad requestId={requestId} actionId={actionId} />
      ) : (
        <SignaturePadPaper
          onDrawnSignatureComplete={onDrawnSignature}
          onTextualSignatureComplete={onTextualSignature}
          loading={signDocumentMutation.isLoading}
          onClose={() => dialogProps.onClose?.({}, 'backdropClick')}
        />
      )}
    </ResponsiveDialog>
  );
};