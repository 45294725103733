import { DocumentFileNameAndIcon } from '@modules/documents/components/DocumentFilenameAndIcon';
import { FormControl, FormLabel, ListItem, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FormFillerValues } from '../../FormFiller/types';
import { FieldViewerProps } from './FieldViewer';

export const FilesViewer: React.FC<FieldViewerProps> = ({ field, index }) => {
  const { submissionId } = useParams();
  const { formatMessage } = useIntl();

  const { control } = useFormContext<FormFillerValues>();

  const { fields: files } = useFieldArray({ control, name: `values.${index}.files` });

  return (
    <FormControl disabled>
      <FormLabel>{field.name}</FormLabel>
      {files.length === 0 && (
        <Typography>
          {formatMessage({ id: 'No files uploaded' })}
        </Typography>
      )}

      {files.length > 0 && files.map(file => (
        <ListItem disablePadding key={file.id} sx={{ border: t => `1px solid ${t.palette.divider}` }}>
          <DocumentFileNameAndIcon
            fileName={file.fileName}
            downloadLink={`/${getTenantIdentifier()}/api/Form/Submission/${submissionId}/Attachments/${file.id}`}
          />
        </ListItem>
      ))}
    </FormControl >
  );
};