import { DataTable } from '@components/DataTable';
import { PageSection } from '@components/PageSection/PageSection';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { DataGridProProps, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMoneyFormatter } from '@utils/numberUtils';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useResponsive } from '@utils/useResponsive';
import { BudgetItemsQuery, useAddBudgetItemMutation, useBudgetItemsQuery, useEditBudgetItemMutation, useExpensesQuery, useGetProjectBudgetQuery } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { BudgetItemActions } from './BudgetItemActions';
import { ExpenseDrawer } from './Expenses/ExpenseDrawer';

type BudgetItem = BudgetItemsQuery['budgetItems'][number];

interface Props extends Omit<DataGridProProps<BudgetItem>, 'rows' | 'columns'> {
  budgetItems: BudgetItem[];
}

const commonColumnOptions: Partial<GridColDef<BudgetItem>> = {
  sortable: false,
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  editable: true,
};

const isBudgetItemUnchanged = (a: BudgetItem, b: BudgetItem) => a.name === b.name && a.budgetedAmount === b.budgetedAmount;

export const BudgetItemTable: React.FC<Props> = ({ budgetItems: _budgetItems, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const { isArchived, projectId, canManageBudgetAndExpenses } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { isMobile } = useResponsive();

  const apiRef = useGridApiRef();

  const [isAddingExpenseForBudgetItemId, setIsAddingExpenseForBudgetItemId] = useState<number>();
  const isAddingExpense = isAddingExpenseForBudgetItemId != null;

  const formatMoney = useMoneyFormatter();

  const columns: GridColDef<BudgetItem>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      flex: 2,
      minWidth: 200,
      renderCell: ({ value, row }) => (
        <Typography fontStyle={row.id ? 'normal' : 'italic'} title={value}>
          {value}
        </Typography>
      )
    },
    {
      field: 'budgetedAmount',
      headerName: formatMessage({ id: 'Budgeted amount' }),
      valueFormatter: ({ value }) => formatMoney(value),
      flex: 1,
      minWidth: 200,
      renderCell: ({ formattedValue, row }) => !row.id ? null : (
        <Typography fontWeight={600}>
          {formattedValue}
        </Typography>
      )
    },
    {
      field: 'totalExpendedAmount',
      headerName: formatMessage({ id: 'Expense amount' }),
      flex: 1,
      editable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row.expenses == null ? undefined : row.expenses.reduce((acc, e) => acc + e.expendedAmount, 0),
      valueFormatter: ({ value }) => isNaN(value) ? '-' : formatMoney(value),
      renderCell: ({ formattedValue, value, row }) => {
        const isOverBudget = value > row.budgetedAmount;

        return <>
          <Stack
            height='100%'
            width='100%'
            alignItems='center'
            direction='row'
            gap={1}
            sx={{
              '&:hover .showOnHover': {
                visibility: 'visible',
              }
            }}
          >
            <Typography color={isOverBudget ? 'error.main' : 'success.main'}>
              {formattedValue}
            </Typography>

            {!!row.id && !isMobile && canManageBudgetAndExpenses && (
              <IconButton size='small' className='showOnHover' sx={{ visibility: 'hidden' }} onClick={e => {
                e.stopPropagation();
                setIsAddingExpenseForBudgetItemId(row.id);
              }}>
                <Add />
              </IconButton>
            )}
          </Stack>
        </>;
      }
    },
    {
      field: 'balance',
      headerName: formatMessage({ id: 'Balance' }),
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: ({ row }) => {
        if (row.expenses == null) {
          return undefined;
        }

        const totalExpendedAmount = row.expenses.reduce((acc, e) => acc + e.expendedAmount, 0);

        return row.budgetedAmount - totalExpendedAmount;
      },
      valueFormatter: ({ value }) => isNaN(value) ? '-' : formatMoney(value),
      renderCell: ({ formattedValue, value, row }) => {
        if (!row.id) return null;

        const isOverBudget = value < 0;

        return (
          <Typography color={isOverBudget ? 'error.main' : 'success.main'}>
            {formattedValue}
          </Typography>
        );
      }
    },
    {
      field: 'action',
      renderHeader: () => null,
      width: 64,
      editable: false,
      renderCell: isArchived ? undefined : ({ row }) => !row.id ? null : (
        <BudgetItemActions
          budgetItem={row}
          onAddExpense={() => setIsAddingExpenseForBudgetItemId(row.id)}
          onEdit={() => {
            apiRef.current.startRowEditMode({ id: row.id, fieldToFocus: 'budgetedAmount' });
          }}
        />
      ),
    }
  ];


  const onSuccess = () => {
    invalidateQuery(useBudgetItemsQuery, { projectId });
    invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
    notifySuccess(formatMessage({ id: 'Budget updated successfully.' }));
  };

  const { mutate: addBudgetItem, isLoading: isAddingRow } = useAddBudgetItemMutation({ onSuccess });

  const { mutate: editBudgetItem, isLoading: isUpdatingRow } = useEditBudgetItemMutation({ onSuccess });

  const processRowUpdate: DataGridProProps<BudgetItem>['processRowUpdate'] = (newRow, oldRow) => {
    if (isBudgetItemUnchanged(newRow, oldRow)) {
      return oldRow;
    }

    const budgetedAmount = isNaN(Number(newRow.budgetedAmount)) ? undefined : Number(newRow.budgetedAmount);

    if (budgetedAmount === undefined || newRow.name.trim() === '' || budgetedAmount < 0) {
      return oldRow;
    }

    editBudgetItem({
      input: {
        projectId,
        id: newRow.id,
        name: newRow.name,
        budgetedAmount
      }
    });

    return newRow;
  };


  const { data: unassignedExpenses } = useExpensesQuery({
    projectId,
    filter: { budgetItemId: { eq: null } }
  }, { select: d => d.expenses });

  const unassignedExpenseBudgetItem: BudgetItem = useMemo(() => ({
    id: 0,
    name: formatMessage({ id: 'Unassigned expenses' }),
    budgetedAmount: 0,
    expenses: unassignedExpenses ?? []
  }), [formatMessage, unassignedExpenses]);

  const budgetItems = useMemo(() => {
    let items = _budgetItems;

    if (unassignedExpenses?.length !== 0) {
      items = [...items, unassignedExpenseBudgetItem];
    }

    return items;
  }, [_budgetItems, unassignedExpenseBudgetItem, unassignedExpenses?.length]);

  return (
    <PageSection
      title={formatMessage({ id: 'Budget items' })}
      actionButton={!isArchived && canManageBudgetAndExpenses && (

        <ResponsiveButton
          onClick={() => addBudgetItem({
            input: {
              name: formatMessage({ id: 'New budget item' }),
              budgetedAmount: 0,
              projectId: projectId
            }
          })}
          variant='contained' color='primary' icon={<Add />}>
          {formatMessage({ id: 'Add budget item' })}
        </ResponsiveButton>
      )}
    >
      <DataTable
        apiRef={apiRef}
        editMode='row'
        isCellEditable={({ row }) => row.id !== 0 && canManageBudgetAndExpenses}
        loading={isAddingRow || isUpdatingRow}
        onCellClick={cell => {
          if (cell.colDef.field === 'action') return;

          apiRef.current.startRowEditMode({ fieldToFocus: cell.colDef.field, id: cell.row.id });

        }}

        processRowUpdate={processRowUpdate}
        columns={columns.map(c => ({ ...commonColumnOptions, ...c }))}
        rows={budgetItems}
        noDataMessage={formatMessage({ id: 'No budget items.' })}
        {...dataTableProps}
      />


      <ExpenseDrawer
        open={isAddingExpense}
        onClose={() => setIsAddingExpenseForBudgetItemId(undefined)}
        budgetItemId={isAddingExpenseForBudgetItemId}
      />
    </PageSection>
  );
};