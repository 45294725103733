import { ReactComponent as UnexpectedErrorImage } from '@assets/images/undraw_unexpected-error.svg';
import { AnonymousContextProvider } from '@modules/projectAccessTokens/AnonymousContextProvider';
import { Button, Container, Stack, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ErrorDetails } from '../components/ErrorDetails';
import { ErrorPageLayout } from '../layouts/ErrorPageLayout';


export const ErrorBoundaryPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const reloadApplication = () => window.location.reload();
  const theme = useTheme();
  const { token } = useParams();


  const content = (
    <ErrorPageLayout>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <ErrorDetails
          image={<UnexpectedErrorImage fill={theme.palette.primary.main} />}
          title={formatMessage({ id: 'An unexpected error has occurred' })}
          message={formatMessage({ id: 'You can try to reload the application and retry the operation. Contact support if the error persists.' })}
        />
        <Stack direction="row" justifyContent='center' alignItems='center' spacing={2} sx={{ marginTop: 3 }}>
          <Button variant="contained" onClick={reloadApplication}>{formatMessage({ id: 'Reload application' })}</Button>
        </Stack>
      </Container>
    </ErrorPageLayout>
  );

  if (token) {
    return <AnonymousContextProvider accessToken={token}>
      {content}
    </AnonymousContextProvider>;
  } else {
    return <>
      {content}
    </>;
  }
};