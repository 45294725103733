import { ReactNode } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { allSupportedLocales, defaultLocale } from 'src/locales';
import { currentLocaleState } from '../atoms';
import { LocalizationProvider as DateProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface ErrorWithCode extends Error {
  code: string;
}

export const LocalizationProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
  const currentLocale = useRecoilValue(currentLocaleState);
  const locale = allSupportedLocales.find(locale => locale.code.includes(currentLocale)) || defaultLocale;

  const ignoreMissingTranslations = (error: ErrorWithCode) => {
    if (error.code !== ReactIntlErrorCode.MISSING_TRANSLATION)
      throw error;
  };

  return (
    <IntlProvider
      locale={locale.code}
      messages={locale.messages}
      onError={ignoreMissingTranslations}
    >
      <DateProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale.toLowerCase() ?? 'fr-ca'}>
        {children}
      </DateProvider>
    </IntlProvider>
  );
};