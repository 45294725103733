import { Draggable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ListItem, ListItemText, Stack, styled } from '@mui/material';
import { useContext } from 'react';
import { EditorSavingStatus, SavingStatusContext } from './TopBar/SavingStatusContext';

type DraggableItemTypeProps = {
  id: string;
  index: number;
  icon: React.ReactNode,
  text: string;
  disabled?: boolean;
  hidden?: boolean;
};

const DraggableListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isDragging'
})<{
  isDragging?: boolean;
}>(({ isDragging, theme }) => ({
  paddingRight: theme.spacing(2),
  backgroundColor: isDragging ? theme.palette.background.paper : 'inherit',
  border: isDragging ? `1px solid ${theme.palette.divider}` : 'inherit',
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
  ':hover .showOnHover': {
    visibility: 'visible'
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none'
  },
  cursor: 'grab',
  userSelect: 'none'
}));


export const DraggableItemType = (props: DraggableItemTypeProps) => {
  const { isEditorLoading, editorSavingStatus } = useContext(SavingStatusContext);
  const isLoading = isEditorLoading || editorSavingStatus === EditorSavingStatus.Saving;
  const content = <>
    <Stack direction='row' alignItems='center' gap={2} p={1} pl={3} width='100%'>
      {props.icon}

      <ListItemText primary={props.text} />
    </Stack>
  </>;

  if (props.hidden) {
    return undefined;
  }

  return (
    <Draggable draggableId={props.id} index={props.index} isDragDisabled={isLoading || props.disabled}>
      {(provided, snapshot) => (
        <>
          <DraggableListItem
            disablePadding
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)'
            }}
          >
            {content}

            <DragIndicatorIcon className='showOnHover' sx={{ visibility: 'hidden' }} />
          </DraggableListItem>

          {snapshot.isDragging && (

            <DraggableListItem>
              {content}
            </DraggableListItem>
          )}
        </>
      )}
    </Draggable >
  );

};