import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useStartWorkflowMutation, useWorkflowInstancesQuery } from 'gql/index';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConfigureVariablesStep } from './components/ConfigureVariablesStep';
import { SelectWorkflowTemplateStep } from './components/SelectWorkflowTemplateStep';
import { StartWorkflowFormValues } from './types';


export const StartWorkflowDialog: React.FC<DialogProps> = (dialogProps) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();
  const navigate = useNavigate();

  const form = useForm<StartWorkflowFormValues>();

  useEffect(() => {
    if (!dialogProps.open) {
      form.reset();
    }
  }, [dialogProps.open, form]);

  const { mutate: startWorkflow, isLoading: isStartingWorkflow } = useStartWorkflowMutation();

  const onStartWorkflow = (values: StartWorkflowFormValues) => {
    startWorkflow({
      input: {
        projectId: projectId,
        workflowDefinitionId: values.workflowTemplateId,
        workflowVariables: values.variableInputs.filter(p => typeof p.id !== 'string').map(p => ({
          variableId: p.id as number,
          serializedValue: p.value == null ? null : JSON.stringify(p.value)
        }))
      }
    }, {
      onSuccess: e => {
        invalidateQuery(useWorkflowInstancesQuery);
        const id = e.startWorkflowInstance.workflowInstance?.id;
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/workflows/${id}`);
      }
    });
  };

  const selectedTemplateId = form.watch('workflowTemplateId');

  return (
    <FormProvider {...form}>
      <MultiStepFormDialog
        dialogProps={dialogProps}

        onSubmit={form.handleSubmit(onStartWorkflow)}
        isSubmitting={isStartingWorkflow}
        steps={[
          {
            title: formatMessage({ id: 'Select a workflow' }),
            content: () => <SelectWorkflowTemplateStep />,
            onNext: form.handleSubmit,
            disableNext: selectedTemplateId == null
          },
          {
            title: formatMessage({ id: 'Configure workflow variables' }),
            content: () => <ConfigureVariablesStep />
          }
        ]}
      />
    </FormProvider>
  );
};