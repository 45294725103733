import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { ReactNode, useMemo } from 'react';
import { useErrorHandler } from '../useErrorHandler';

const isDevtoolsShown = import.meta.env.DEV && import.meta.env.VITE_REACT_QUERY_DEVTOOLS == 'true';

export const QueryProvider: React.FC<{ children?: ReactNode; }> = ({ children }) => {
  const { handleError } = useErrorHandler();
  const queryCache = useMemo(() => new QueryCache({

    onSuccess: (data, query) => {
      if (typeof (data) !== 'object') return;

      const dataIsNull = data === null || Object.values(data).every(v => v === null);

      if (!query.meta?.nullable && dataIsNull) {
        console.error('Empty graphql response. Redirecting to 404.');
        const tenantIdentifier = getTenantIdentifier();
        const errorPageUrl = tenantIdentifier ? `${location.origin}/${getTenantIdentifier()}/error/404` : `${location.origin}/error/404`;
        window.location.href = errorPageUrl;
      }
    }
  }), []);

  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 0,
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError: handleError,
        retry: false
      }
    }, queryCache
  }), [handleError, queryCache]);

  return (
    <QueryClientProvider client={queryClient}>
      {isDevtoolsShown && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {children}
    </QueryClientProvider>
  );

};