import { useShareableResourceInvalidator } from '@modules/securityGroups/components/Permissions/invalidator';
import { ShareableResource } from '@modules/securityGroups/components/Permissions/types';
import { PermissionsModalContext } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import React, { useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { BuiltInRoles, useOrganizationUsersQuery, useShareOrganizationResourceMutation } from '../../../../../gql';
import { notNullOrUndefined } from '../../../../../utils/notNullOrUndefined';
import { useValidationRules } from '../../../../../utils/useValidationRules';
import { userRoleMessages } from '../../../../application/messages';

interface FormValues {
  userIds: string[];
  role: BuiltInRoles | '';
  message: string | '';
  sendNotificationEmail: boolean;
}

interface Props {
  resource: ShareableResource;
}

export const OrganizationGrantAccessView: React.FC<Props> = ({ resource }) => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();

  const { goToManageAccess } = useContext(PermissionsModalContext);

  const { data, isFetching } = useOrganizationUsersQuery({
    filter: {
      id: {
        neq: me?.id
      }
    }
  }, { select: d => d.organizationUsers });
  const users = data ?? [];

  const { notEmpty } = useValidationRules();
  const invalidator = useShareableResourceInvalidator(resource);
  const { notifySuccess } = useNotification();

  const defaultValues: FormValues = {
    userIds: [],
    role: BuiltInRoles.Reader,
    message: '',
    sendNotificationEmail: true
  };

  const { handleSubmit, control, setValue, register, formState: { errors: { userIds: userErrors } } } = useForm<FormValues>({ defaultValues });

  const onSharingSuccess = () => {
    invalidator();
    notifySuccess(formatMessage({ id: 'Resource shared successfully.' }));
    goToManageAccess();
  };

  const { mutate: shareResource, isLoading } = useShareOrganizationResourceMutation({ onSuccess: onSharingSuccess });

  const onSubmit = (values: FormValues) => {
    for (const userId of values.userIds) {
      shareResource({
        input: {
          principalId: userId,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          sendNotification: values.sendNotificationEmail,
          notificationMessage: values.message
        }
      });
    }
  };

  const userIds = useWatch({ control, name: 'userIds' });

  const value = userIds.map(id => users.find(m => m.id === id)).filter(notNullOrUndefined);

  const sendNotificationEmail = useWatch({ control, name: 'sendNotificationEmail' });

  register('userIds', {
    validate: (_, formValues) => {
      if (formValues.userIds.length === 0) return formatMessage({ id: 'At least one user or group must be selected.' });
    }
  });

  return <Stack gap={2}>
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography variant='h5'>{formatMessage({ id: 'Grant Access' })}</Typography>
    </Stack>

    <Stack direction={'row'} spacing={2} justifyContent={'left'} alignItems={'center'} onClick={() => goToManageAccess()} sx={{ cursor: 'pointer' }}>
      <ArrowBackIcon />
      <Typography>{formatMessage({ id: 'Manage Access' })}</Typography>
    </Stack>

    <Stack p={{ xs: 1, md: 2 }} gap={3}>
      <Autocomplete
        multiple
        disabled={isLoading}
        loading={isFetching}
        options={users}
        renderInput={params => (
          <TextField
            {...params}
            disabled={isLoading}
            label={formatMessage({ id: 'Users' })}
            error={!!userErrors}
            helperText={userErrors?.message}
          />
        )}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        getOptionLabel={user => user.fullName}
        value={value}
        onChange={(_, value) => {
          setValue('userIds', value.map(v => v.id));
        }}
      />

      <Controller
        control={control}
        name='role'
        rules={{
          validate: notEmpty
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            select
            disabled
            SelectProps={{ disabled: true }}
            {...field}
            required
            label={formatMessage({ id: 'Role' })}
            error={!!error}
            helperText={error?.message}
          >
            <MenuItem value={BuiltInRoles.Reader}>
              {formatMessage(userRoleMessages[BuiltInRoles.Reader])}
            </MenuItem>
          </TextField>
        )}
      />

      <Controller
        control={control}
        name='message'
        rules={{
          maxLength: { value: 250, message: formatMessage({ id: 'The message must be at most {count} characters.' }, { count: 250 }) }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            multiline
            disabled={isLoading || !sendNotificationEmail}
            minRows={6}
            maxRows={10}
            label={formatMessage({ id: 'Message' })}
            error={!!error}
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            placeholder={formatMessage({ id: 'Type to personalize email notification...' })}
          />
        )}
      />
    </Stack>

    <Stack direction='row' justifyContent='space-between'>
      <Controller
        control={control}
        name='sendNotificationEmail'
        render={({ field }) => (
          <FormGroup sx={{ px: 2 }}>
            <FormControlLabel
              disabled={isLoading}
              control={<Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
              label={formatMessage({ id: 'Send Notification' })}
            />
          </FormGroup>
        )}
      />

      <LoadingButton loading={isLoading} variant='contained' onClick={handleSubmit(onSubmit)}>{formatMessage({ id: 'Grant Access' })}</LoadingButton>
    </Stack>
  </Stack>;
};