import dayjs from 'dayjs';
import { AtomEffect, atom } from 'recoil';
import { allSupportedLocales, defaultLocale } from 'src/locales';
import { getFromLocalStorage, tryRemoveFromLocalStorage, trySetInLocalStorage } from '../../utils/storageUtils';

export const localeStorageKey = 'preferredLocale';
export const themeStorageKey = 'preferredTheme';
export const displayTenantSwitcherAfterLogin = 'displayTenantSwitcherAfterLogin';

export const initializeLocaleFromBrowser: AtomEffect<string> = ({ setSelf }) => {
  const currentBrowserLocale = allSupportedLocales.find(locale => locale.code === navigator.language);
  if (currentBrowserLocale != null) {
    setSelf(currentBrowserLocale.code);
    dayjs.locale(currentBrowserLocale.code);
  }
};

export const initializeLocaleFromLocalStorage = (localStorageKey: string): AtomEffect<string> => ({ setSelf, onSet }) => {
  const localStorageValue = getFromLocalStorage(localStorageKey);
  if (localStorageValue != null) {
    setSelf(localStorageValue);
    dayjs.locale(localStorageValue);
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? tryRemoveFromLocalStorage(localStorageKey)
      : trySetInLocalStorage(localStorageKey, newValue);
  });
};

export const initializeFromLocalStorage = <T = string | boolean>(localStorageKey: string): AtomEffect<T> => ({ setSelf, onSet }) => {
  const localStorageValue = getFromLocalStorage(localStorageKey);
  if (localStorageValue != null) {
    setSelf(JSON.parse(localStorageValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? tryRemoveFromLocalStorage(localStorageKey)
      : trySetInLocalStorage(localStorageKey, JSON.stringify(newValue));
  });
};

export const applyLocaleGlobally: AtomEffect<string> = ({ onSet }) => {
  onSet((newValue) => dayjs.locale(newValue));
};

export const isNavigationMenuOpenState = atom<boolean>({
  key: 'isNavigationMenuOpenState',
  default: false,
});

export const isNavigationMenuCollapsedState = atom<boolean>({
  key: 'isNavigationMenuCollapsedState',
  default: false,
  effects: [
    initializeFromLocalStorage('isNavigationMenuCollapsed')
  ]
});

export const isSettingsMenuOpenState = atom<boolean>({
  key: 'isSettingsMenuOpenState',
  default: false
});

export const currentLocaleState = atom<string>({
  key: 'currentLocaleState',
  default: defaultLocale.code,
  effects: [
    initializeLocaleFromBrowser,
    initializeLocaleFromLocalStorage(localeStorageKey),
    applyLocaleGlobally
  ]
});

export const currentThemeNameState = atom<string>({
  key: 'currentThemeNameState',
  default: 'light',
  effects: [initializeLocaleFromLocalStorage(themeStorageKey)]
});

export const displayTenantSwitcherAfterLoginState = atom<boolean>({
  key: 'displayTenantSwitcherAfterLoginState',
  default: false,
  effects: [initializeFromLocalStorage(displayTenantSwitcherAfterLogin)]
});