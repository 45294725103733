import { SlideDialog } from '@components/Dialogs/SlideDialog';
import { Info } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { FieldType, FormValueFragmentFragment, useFormTemplateQuery, usePreviewProjectFormQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormTemplateActions } from './FormTemplateActions';
import { FormViewer } from './FormViewer/FormViewer';

interface Props {
  open: boolean;
  onClose?: () => void;
  projectFormId?: number;
  templateId?: number;
}

export const FormEditorPreviewDrawer: React.FC<Props> = ({ open, onClose, projectFormId, templateId }) => {
  const { formatMessage } = useIntl();

  const { data: projectForm } = usePreviewProjectFormQuery({ projectFormId: projectFormId ?? 0 }, {
    cacheTime: 0,
    keepPreviousData: false,
    select: d => d.projectForm?.formDefinition,
    enabled: (!!projectFormId && open)
  });

  const { data: formTemplate } = useFormTemplateQuery({ id: templateId ?? 0 }, {
    cacheTime: 0,
    keepPreviousData: false,
    select: d => d.formTemplate,
    enabled: (!!templateId && open)
  });

  const matrixDefaultValues = (projectForm ?? formTemplate?.formDefinition)?.sections
    .flatMap(p => p.fields)
    .filter(p => p.fieldType === FieldType.Matrix)
    .map<FormValueFragmentFragment>(p => ({
      id: p.id,
      files: [],
      selectedOptions: [],
      fieldId: p.id,
      matrixRows: p.matrix?.defaultRows ?? []
    })) ?? [];

  return (
    <SlideDialog
      open={open}
      onClose={onClose}
      topbar={{
        title: formatMessage({ id: 'Preview' }),
        actionButton: formTemplate ? (
          <FormTemplateActions template={formTemplate} />
        ) : undefined
      }}
      containerProps={{ maxWidth: 'xl' }}
    >
      {formTemplate && (
        <Alert color='info' icon={<Info />} sx={{ mb: 2 }}>
          {formatMessage({ id: 'You cannot edit form templates created by another user. You may duplicate it to have a copy to edit.' })}
        </Alert>
      )}

      {formTemplate?.formDefinition && (
        <FormViewer
          editable
          definition={formTemplate.formDefinition}
          values={matrixDefaultValues}
        />
      )}

      {projectForm && (
        <FormViewer
          editable
          definition={projectForm}
          values={matrixDefaultValues}
        />
      )}
    </SlideDialog>
  );
};