import { ActionMenu } from '@components/ActionMenu';
import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { FormControl, FormControlLabel, FormHelperText, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { useAddFormConditionMutation, useDeleteFormConditionMutation, useEditFormConditionMutation } from 'gql/index';
import { debounce } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorContext } from '../FormEditorContext';
import { FormEditorValues } from '../types';
import { FormConditionActions } from './FormConditionActions';
import { TargetItem } from './FormConditionsForm';

type Props = {
  fieldIndex: number,
  conditionTargets: TargetItem[],
  yesNoFields: TargetItem[];
};


export const FormCondition: React.FC<Props> = ({ fieldIndex, conditionTargets, yesNoFields }) => {
  const { control, getValues, handleSubmit, setValue } = useFormContext<FormEditorValues>();
  const { conditionsFieldArray } = useContext(FormEditorContext);
  const { formatMessage } = useIntl();
  const { setEditorSavingStatus, editorSavingStatus } = useContext(SavingStatusContext);
  const { notEmpty } = useValidationRules();

  const { onEditionError: onError, onEditionSuccess: onSuccess } = useEditorHelpers();

  const { mutate: saveCondition, isLoading: isAddingCondition } = useAddFormConditionMutation({ onError, onSuccess });
  const { mutate: editCondition, isLoading: isEditingCondition } = useEditFormConditionMutation({ onError, onSuccess });
  const { mutate: deleteCondition, isLoading: isDeletingCondition } = useDeleteFormConditionMutation({ onError, onSuccess });

  const targetSectionId = useWatch({ control: control, name: `conditions.${fieldIndex}.targetSectionId` });
  const isLoading = isAddingCondition || isEditingCondition || isDeletingCondition;

  const onSave = debounce((form: FormEditorValues) => {
    if (isDeletingCondition || isAddingCondition || isEditingCondition) return;

    const condition = form.conditions[fieldIndex];

    if (condition.id == '' && !isAddingCondition) {
      setEditorSavingStatus(EditorSavingStatus.Saving);

      saveCondition({
        input: {
          formDefinitionId: getValues('id'),
          triggerFieldId: condition.conditionFieldId == '' ? -1 : condition.conditionFieldId,
          effectFieldId: condition.targetFieldId == '' ? null : condition.targetFieldId,
          effectSectionId: condition.targetSectionId == '' ? null : condition.targetSectionId,
          effectVisibility: { visible: true },
          triggerValueEquality: { boolean: !!condition.conditionFieldWhenValueBool }
        }
      }, {
        onSuccess: (result) => {
          setValue(`conditions.${fieldIndex}.id`, result.addCondition.formCondition?.id ?? '');
        },
      });
    }
    else if (condition.id != '') {
      setEditorSavingStatus(EditorSavingStatus.Saving);

      editCondition({
        input: {
          conditionId: condition.id,
          formDefinitionId: getValues('id'),
          triggerFieldId: condition.conditionFieldId == '' ? -1 : condition.conditionFieldId,
          effectFieldId: condition.targetFieldId == '' ? null : condition.targetFieldId,
          effectSectionId: condition.targetSectionId == '' ? null : condition.targetSectionId,
          effectVisibility: { visible: true },
          triggerValueEquality: { boolean: !!condition.conditionFieldWhenValueBool }
        }
      });
    }
  }, 500);

  const onDeleteCondition = useCallback(() => {
    const conditionId = getValues(`conditions.${fieldIndex}.id`);
    if (conditionId) {
      setEditorSavingStatus(EditorSavingStatus.Saving);

      deleteCondition({ input: { formDefinitionId: getValues('id'), id: conditionId } });
    }

    conditionsFieldArray.remove(fieldIndex);
  }, [conditionsFieldArray, deleteCondition, fieldIndex, getValues, setEditorSavingStatus]);

  return <Paper sx={{ width: '100%', p: 2 }}>
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width='100%'>
      <Typography variant='h6'>{`${formatMessage({ id: 'Condition' })} ${fieldIndex + 1}`}</Typography>

      <ActionMenu buttonProps={{ disabled: editorSavingStatus === EditorSavingStatus.Saving }} keepMounted>
        <FormConditionActions onDelete={onDeleteCondition} />
      </ActionMenu>
    </Stack>

    <Stack p={2} pr={6} pb={0} gap={1} width='100%'>
      <Stack direction={'row'}>
        <Controller
          control={control}
          name={`conditions.${fieldIndex}.conditionFieldId`}
          rules={{
            validate: {
              notEmpty,
              differentFromTarget: (value) => value != getValues(`conditions.${fieldIndex}.targetFieldId`) || formatMessage({ id: 'The condition field must be different from the target field.' })
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl sx={{ flexGrow: 1 }} error={!!error?.message}>
              <FormControlLabel
                sx={{ alignSelf: 'start', gap: 2, width: '100%' }}
                label={formatMessage({ id: 'When' })}
                labelPlacement='start'
                control={(
                  <Select
                    disabled={editorSavingStatus === EditorSavingStatus.Saving}
                    {...field}
                    onChange={e => { field.onChange(e); handleSubmit(onSave)(); }}
                    error={!!error?.message}
                    fullWidth
                  >
                    {yesNoFields.map(p => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`conditions.${fieldIndex}.conditionFieldWhenValueBool`}
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error?.message}>
              <FormControlLabel
                sx={{ alignSelf: 'start', pl: 2, gap: 2 }}
                label={formatMessage({ id: 'equals' })}
                labelPlacement='start'
                control={(
                  <Select
                    disabled={editorSavingStatus === EditorSavingStatus.Saving}
                    onChange={e => { setValue(`conditions.${fieldIndex}.conditionFieldWhenValueBool`, e.target.value === 'yes'); handleSubmit(onSave)(); }}
                    value={field.value === true ? 'yes' : 'no'}
                    error={!!error?.message}
                  >
                    <MenuItem value={'yes'}>{formatMessage({ id: 'Yes' })}</MenuItem>
                    <MenuItem value={'no'}>{formatMessage({ id: 'No' })}</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Stack>


      <Controller
        control={control}
        name={`conditions.${fieldIndex}.targetFieldId`}
        rules={{
          validate: {
            sectionOrFieldSet: (value, formValues) => (value == '') !== (formValues.conditions[fieldIndex].targetSectionId == '')
              || formatMessage({ id: 'Either a field or a section must be set as the target of the condition.' })
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl error={!!error?.message} disabled={isLoading}>
            <FormControlLabel
              sx={{ alignSelf: 'start', gap: 2, width: '100%', textWrap: 'nowrap' }}
              label={formatMessage({ id: 'Show field' })}
              labelPlacement='start'
              control={(
                <Select
                  disabled={editorSavingStatus === EditorSavingStatus.Saving}
                  value={field.value ? `field/${field.value}` : targetSectionId ? `section/${targetSectionId}` : ''}
                  onChange={e => {
                    if (typeof (e.target.value) != 'string') return;

                    const valueParts = e.target.value.split('/');
                    if (valueParts[0] === 'section') {
                      setValue(`conditions.${fieldIndex}.targetSectionId`, Number(valueParts[1]));
                      setValue(`conditions.${fieldIndex}.targetFieldId`, '');
                    } else {
                      setValue(`conditions.${fieldIndex}.targetSectionId`, '');
                      setValue(`conditions.${fieldIndex}.targetFieldId`, Number(valueParts[1]));
                    }
                    handleSubmit(onSave)();
                  }}
                  error={!!error?.message}
                  fullWidth
                >
                  <MenuItem disabled value="">
                    <em>{formatMessage({ id: 'Sections' })}</em>
                  </MenuItem>
                  {conditionTargets.filter(p => p.type === 'section').map(p => (
                    <MenuItem key={p.id} value={`section/${p.id}`} sx={{ fontStyle: !p.name ? 'italic' : undefined }}>
                      {p.name || formatMessage({ id: 'Untitled section' })}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="">
                    <em>{formatMessage({ id: 'Fields' })}</em>
                  </MenuItem>
                  {conditionTargets.filter(p => p.type === 'field').map(p => (
                    <MenuItem key={p.id} value={`field/${p.id}`}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </Stack>
  </Paper >;
};
