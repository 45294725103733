import { ConfirmDialog } from '@components/ConfirmDialog';
import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { getFileExtension } from '@components/Icons/FileIcon';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { ProjectDocumentsContext } from '@modules/folders/components/ProjectDocumentsContextProvider';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Delete, Draw, Edit, EditOff, Share } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { DocumentFragmentFragment, ShareableResourceType, useFolderEntriesQuery, useGetDocumentQuery, useProjectDocumentsQuery, useProjectTasksQuery, useRemoveDocumentMutation, useRemoveSignatureMutation } from 'gql/index';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { SignatureDialog } from './DocumentSignatureDialog';
import { RenameDocumentDialog } from './RenameDocumentDialog';
import { RequestSignatureDialog } from './RequestSignatureDialog/RequestSignatureDialog';

interface Props {
  document: DocumentFragmentFragment;
  renameAction?: () => void;
}

export const DocumentActions: React.FC<Props> = ({ document, renameAction }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
  const { notifySuccess } = useNotification();
  const { me } = useGetMe();
  const { canManageDocuments, canManageDocumentPublicLinkSharing } = useCurrentProject();
  const isPdfDocument = getFileExtension(document.fileName).toLocaleLowerCase() == 'pdf';
  const currentUserCanSign = !document?.isSigned && document?.signatoryCustomerId === me?.id;

  const { projectId, isArchived } = useCurrentProject();
  const { setSelectedEntryId } = useContext(ProjectDocumentsContext);

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [isAddingRoleAssignment, setIsAddingRoleAssignment] = useState(false);
  const [isSignatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [openAskSignatureDialog, setOpenAskSignatureDialog] = useState(false);

  const deleteDocumentMutation = useRemoveDocumentMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Document deleted' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      setIsConfirmRemoveDialogOpen(false);
    }
  });

  const deleteDocument = async () => {
    if (!document) return;

    setSelectedEntryId?.(undefined);

    await deleteDocumentMutation.mutateAsync({ input: { documentId: document.id, projectId } });
  };

  const onRenameClicked = () => {
    if (renameAction) {
      renameAction();
    } else {
      setRenameDialogOpen(true);
    }
  };

  const { mutate: removeSignature } = useRemoveSignatureMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Signature removed successfully.' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId });
      invalidateQuery(useGetDocumentQuery, { id: document.id });
      invalidateQuery(useProjectTasksQuery, { projectId });
    }
  });

  const handleRemoveSignature = () => {
    document && removeSignature({ input: { projectId: projectId, documentId: document.id } });
  };

  return <>
    <DownloadLinkButton
      variant='MenuItem'
      fileName={document?.fileName || ''}
      link={`/${getTenantIdentifier()}/api/Documents/${document?.id}`}
    />

    {currentUserCanSign && (
      <MenuItem disabled={isArchived} onClick={() => setSignatureDialogOpen(true)}>
        <ListItemIcon><Draw /></ListItemIcon>

        <ListItemText primary={formatMessage({ id: 'Sign now' })} />
      </MenuItem>
    )}

    {(canManageDocumentPublicLinkSharing) && <>
      <MenuItem onClick={() => setIsAddingRoleAssignment(true)}>
        <ListItemIcon><Share /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Share' })} />
      </MenuItem>
      {document.publicSlug &&
        <MenuItem onClick={() => {
          navigator.clipboard.writeText(`${window.location.origin}/${getTenantIdentifier()}/shared/${document.publicSlug}`);
          notifySuccess(formatMessage({ id: 'Copied to clipboard' }));
        }}>
          <ListItemIcon><LinkIcon /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Copy public access URL' })} />
        </MenuItem>
      }
    </>
    }

    {canManageDocuments && (document?.isSigned || document?.requiresSignatures ? (
      <MenuItem onClick={handleRemoveSignature} disabled={isArchived}>
        <ListItemIcon><EditOff /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Remove signature' })} />
      </MenuItem>
    ) : (
      <DisableableTooltip disabled={isPdfDocument} title={formatMessage({ id: 'Only pdf documents can be signed.' })}>
        <MenuItem onClick={() => isPdfDocument && setOpenAskSignatureDialog(true)} disabled={!isPdfDocument || isArchived}>
          <ListItemIcon><Draw /></ListItemIcon>

          <ListItemText primary={formatMessage({ id: 'Request signature' })} />
        </MenuItem>
      </DisableableTooltip>
    ))}

    {canManageDocuments && <Divider />}

    {canManageDocuments &&
      <MenuItem onClick={onRenameClicked}>
        <ListItemIcon><Edit /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Rename' })} />
      </MenuItem>}

    {canManageDocuments && <MenuItem onClick={() => setIsConfirmRemoveDialogOpen(true)} disabled={isArchived}>
      <ListItemIcon><Delete /></ListItemIcon>
      <ListItemText primary={formatMessage({ id: 'Delete' })} />
    </MenuItem >}


    <PermissionsModal open={isAddingRoleAssignment} resource={({ resourceType: ShareableResourceType.Document, ...document })} onClose={() => setIsAddingRoleAssignment(false)} />

    <ConfirmDialog
      open={isConfirmRemoveDialogOpen && document != null}
      onCancel={() => setIsConfirmRemoveDialogOpen(false)}
      onConfirm={deleteDocument}
      title={formatMessage({ id: 'Remove from project?' })}
      content={formatMessage({
        id: '{fileName} will be permanently deleted.'
      }, { fileName: document?.fileName })}
      confirmText={formatMessage({ id: 'Remove' })}
      confirmColor='error'
      loading={deleteDocumentMutation.isLoading}
    />

    <RenameDocumentDialog document={document} open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)} />

    <SignatureDialog
      documentId={document.id}
      actionId={document.zohoActionId ?? ''}
      requestId={document.zohoRequestId ?? ''}
      open={isSignatureDialogOpen}
      onClose={() => setSignatureDialogOpen(false)}
    />

    <RequestSignatureDialog
      documentId={document?.id ?? 0}
      open={openAskSignatureDialog}
      onClose={() => setOpenAskSignatureDialog(false)}
    />
  </>;
};