import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { ListItemAvatar, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { PrincipalType } from 'gql/index';
import { isRemovedPrincipal } from '.';
import { PrincipalPickerValue } from './PrincipalPicker';

export const PrincipalPickerMenuItem: React.FC<{ principal: PrincipalPickerValue; } & MenuItemProps> = ({ principal, ...props }) => {
  if (isRemovedPrincipal(principal)) {
    return null;
  }

  if (typeof principal === 'string') {
    return (
      <MenuItem {...props}>
        {principal}
      </MenuItem>
    );
  } else if (principal.principalType === PrincipalType.User) {
    return (
      <MenuItem {...props}>
        <ListItemAvatar>
          <UserAvatar displayName={principal.fullName} />
        </ListItemAvatar>

        <ListItemText
          primary={principal.fullName}
          secondary={principal.email}
        />
      </MenuItem>
    );
  } else if (principal.principalType === PrincipalType.SecurityGroup) {
    return (
      <MenuItem {...props}>
        <ListItemText
          primary={principal.name}
        />
      </MenuItem>
    );
  }
};