import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ApiError, DomainError, TenantMissingError } from '../../gql/graphql-fetcher';
import { useNotification } from '../../utils/useNotification';

export const useErrorHandler = () => {

  const { notifyFailure } = useNotification();
  const { formatMessage } = useIntl();

  const handleError = useCallback((error: unknown) => {

    if (error instanceof ApiError) {
      const tenantIdentifier = getTenantIdentifier();
      const baseErrorPageUrl = tenantIdentifier ? `${location.origin}/${tenantIdentifier}/error` : `${location.origin}/error`;
      if (error.errorCode === 401) {
        window.location.href = `${baseErrorPageUrl}/401`;
      } else if (error.errorCode === 404) {
        window.location.href = `${baseErrorPageUrl}/404`;
      } else if (error.errorCode === 500) {
        window.location.href = `${baseErrorPageUrl}/500`;
      } else {
        notifyFailure(`${error.errorCode} - ${error.message}`);
      }
    }
    else if (error instanceof DomainError) {
      notifyFailure(error.message);
    } else if (error instanceof TenantMissingError) {
      return;
    } else {
      notifyFailure(formatMessage({ id: 'An unexpected error has occurred.' }) + error);
    }
  }, [notifyFailure, formatMessage]);

  return { handleError };
};