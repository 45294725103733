import { max } from 'lodash';
import { ProjectTask } from '../../gql';

type Task = Pick<ProjectTask, 'hierarchyPath' | 'id'>;

type HierarchyPath = string | null | undefined;

const getPathParts = (path: HierarchyPath) => path?.split('/').filter(Boolean) ?? [];

const pathLevel = (path: HierarchyPath) => getPathParts(path).length;

type Hierarchy<T> = {
  path: string;
  task: T;
  children: Hierarchy<T>[];
};

const getHierarchyParent = <T extends Task>(hierarchy: Hierarchy<T>[], path: HierarchyPath): Hierarchy<T> | undefined => {
  if (!path) return;

  let parent: Hierarchy<T> | undefined = undefined;

  for (; ;) {
    hierarchy = parent?.children ?? hierarchy;

    const foundParent = hierarchy.find(h => path.startsWith(h.path));

    if (!foundParent) {
      return parent;
    }

    parent = foundParent;
  }
};

const traverse = <T extends Task>(hierarchy: Hierarchy<T>, pathBase: string): T[] => {
  const result: T[] = [{ ...hierarchy.task, hierarchyPath: pathBase }];

  for (const child of hierarchy.children) {
    result.push(...traverse(child, `${pathBase}${child.task.id}/`));
  }

  return result;
};

export const fixHierarchyPaths = <T extends Task>(tasks: T[]): T[] => {
  const maxLevel = max(tasks.map(task => task.hierarchyPath).map(pathLevel)) ?? 0;

  const hierarchy: Hierarchy<T>[] = [];
  let level = 0;

  while (level <= maxLevel) {
    const levelTasks = tasks.filter(task => pathLevel(task.hierarchyPath) === level);

    for (const task of levelTasks) {
      if (!task.hierarchyPath) continue;

      const parent = getHierarchyParent(hierarchy, task.hierarchyPath);

      if (!parent) {
        hierarchy.push({ path: task.hierarchyPath, task, children: [] });
      } else {
        parent.children.push({ path: task.hierarchyPath, task, children: [] });
      }
    }

    level += 1;
  }

  const result: T[] = [];

  for (const child of hierarchy) {
    result.push(...traverse(child, `/${child.task.id}/`));
  }

  return result;
};