import { EmptyState } from '@components/EmptyState';
import { DragDropContext, OnDragEndResponder } from '@hello-pangea/dnd';
import { Container, Stack } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { FieldType, FormDefinitionFragmentFragment } from 'gql/index';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorContextProvider } from './FormEditorContext';
import { FormMenuItem, FormSideMenu } from './FormSideMenu/FormSideMenu';
import { FormConditionsForm } from './Logic/FormConditionsForm';
import { DroppableFormSections } from './Sections/DroppableFormSections';
import { TitleSection } from './TitleSection';
import { emitFieldCreated, emitFieldMoved, emitSectionCreated, emitSectionMoved } from './events';
import { FieldIds, FormConditionValue, FormEditorDroppableIds, FormEditorDroppableTypes, FormEditorFieldValues, FormEditorValues, fieldIdToFieldTpe } from './types';
import { matrixQueryValuesToFormValues } from './utils';

interface Props {
  formContent?: FormDefinitionFragmentFragment | null;
}

export const FormEditor: React.FC<Props> = ({ formContent }) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();
  const form = useForm<FormEditorValues>({ mode: 'onBlur', defaultValues: { sections: [] } });
  const [selectedMenuItem, setSelectedMenuItem] = useState<FormMenuItem>('fields');


  useEffect(() => {
    if (formContent != null) {
      const defaultValues: FormEditorValues = {
        id: formContent.id,
        name: formContent.name,
        description: formContent.description ?? '',
        sections: formContent.sections.map(s => ({
          id: s.id,
          name: s.name,
          fields: s.fields.map<FormEditorFieldValues>(f => ({
            id: f.id,
            fieldType: f.fieldType,
            name: f.name,
            isRequired: f.isRequired,
            isMultiline: f.isMultiline,
            isMultiselect: f.isMultiselect,
            selectionOptions: f.options?.map(o => ({ id: o.id.toString(), label: o.label })) ?? [],
            matrixValues: matrixQueryValuesToFormValues(f.matrix),
          }))
        }),),
        conditions: formContent.conditions.map<FormConditionValue>(p => ({
          id: p.id,
          targetFieldId: p.effect.targetFieldId ?? '',
          targetSectionId: p.effect.targetSectionId ?? '',
          conditionFieldId: p.trigger.sourceFieldId ?? '',
          conditionFieldWhenValueBool: p.trigger.valueEquality?.boolean ?? false,
        }))
      };

      form.reset(defaultValues);
    }
  }, [form, formContent]);


  const { getValues } = form;

  const onDragEnd: OnDragEndResponder = ({ source, draggableId, destination, type }) => {
    if (!destination) return;

    if (type === FormEditorDroppableTypes.section) {
      if (source.droppableId === FormEditorDroppableIds.layoutSelectionPanel) {
        return emitSectionCreated({
          destinationId: destination.droppableId,
          createdAtIndex: destination.index
        });
      }
      emitSectionMoved({
        sourceIndex: source.index,
        destinationIndex: destination.index
      });
    } else if (type === FormEditorDroppableTypes.field) {
      if (source.droppableId === FormEditorDroppableIds.fieldsSelectionPanel) {
        return emitFieldCreated({
          destinationId: destination.droppableId,
          createdAtIndex: destination.index,
          fieldType: fieldIdToFieldTpe[draggableId as keyof typeof FieldIds] ?? FieldType.Text,
          isMultiline: draggableId == 'multiline'
        });
      }

      const sections = getValues('sections');
      const sourceSection = sections.find(s => s.id?.toString() === source.droppableId);
      const field = sourceSection?.fields.at(source.index);

      if (!field) return;

      return emitFieldMoved({
        destinationIndex: destination.index,
        destinationSectionId: destination.droppableId,
        sourceIndex: source.index,
        sourceSectionId: source.droppableId,
        field
      });
    }
  };

  if (isMobile) {
    return (
      <EmptyState
        title={formatMessage({ id: 'Forms cannot be edited on mobile.' })}
        svgSize={150}
      />
    );
  }

  return <>
    <FormProvider {...form}>
      <FormEditorContextProvider selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem}>
        <Stack overflow='auto'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack direction={{ xs: 'column', md: 'row', }} height={'100%'}>
              <FormSideMenu selectedItem={selectedMenuItem} onChange={setSelectedMenuItem} />


              <Container maxWidth='md' sx={{ pt: 2, flexGrow: 1, display: selectedMenuItem == 'fields' ? 'visible' : 'none' }}>

                <form noValidate autoComplete='off'>
                  <input type='submit' disabled style={{ display: 'none' }} />

                  <TitleSection />

                  <DroppableFormSections />
                </form>

              </Container>

              <FormConditionsForm open={selectedMenuItem === 'logic'} />
            </Stack>
          </DragDropContext>
        </Stack >

      </FormEditorContextProvider>
    </FormProvider >
  </>;
};