import { TreeDataGroupingCell } from '@components/TreeDataGroupingCell';
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FolderFragmentFragment } from 'gql/index';

type Folder = Pick<FolderFragmentFragment, 'id' | 'name'>

type Props = GridRenderCellParams<Folder>

export const FolderTreeGroupingCell: React.FC<Props> = (props) => {
  const { row } = props;

  return (
    <TreeDataGroupingCell {...props}>
      <Typography>
        {(row as Folder).name}
      </Typography>
    </TreeDataGroupingCell>
  );
};