import { BudgetAndExpensesPermission, BuiltInRoles, DocumentsPermission, FormsPermission, ManagePublicFileSharingPermission, TasksPermission, useGetMyProjectRolesQuery, useGetProjectQuery, WorkflowsPermission } from 'gql/index';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMe } from '../../../utils/useGetMe';

interface Options {
  disabled?: boolean;
}

export const useCurrentProject = (options?: Options) => {
  const { projectId: projectIdString } = useParams();
  const { hasTenantAdminRole } = useGetMe();
  if (!projectIdString && !options?.disabled) {
    throw new Error('Attempted to use useCurrentProject hook outside of a project route.');
  }

  const projectId = Number(projectIdString) || 0;

  const { data: projectRoles } = useGetMyProjectRolesQuery({ projectId }, {
    select: d => d.project,
    staleTime: 120000,
    enabled: !options?.disabled
  });

  const { data: project, ...query } = useGetProjectQuery({ id: projectId }, {
    select: d => d.project,
    staleTime: 120000,
    enabled: !options?.disabled
  });

  const isCurrentProjectAdmin = useMemo(() => projectRoles?.myRoles.roles.includes(BuiltInRoles.ProjectAdministrator) || false, [projectRoles]);

  const permissions = projectRoles?.myPermissions;

  const canManageProject = useMemo(() => isCurrentProjectAdmin || hasTenantAdminRole || false, [isCurrentProjectAdmin, hasTenantAdminRole]);

  const canViewBudgetAndExpenses = useMemo(() => permissions?.budgetPermission != BudgetAndExpensesPermission.NotAllowed, [permissions]);
  const canManageBudgetAndExpenses = useMemo(() => permissions?.budgetPermission === BudgetAndExpensesPermission.Manage, [permissions]);

  const canManageForms = useMemo(() => permissions?.formsPermission === FormsPermission.Manage, [permissions]);

  const canManageDocuments = useMemo(() => permissions?.documentsPermission === DocumentsPermission.Manage, [permissions]);

  const canUploadDocuments = useMemo(() => true || canManageDocuments || permissions?.documentsPermission === DocumentsPermission.Contributor, [canManageDocuments, permissions?.documentsPermission]);
  const canManageDocumentPublicLinkSharing = useMemo(() => isCurrentProjectAdmin || permissions?.managePublicFileSharingPermission === ManagePublicFileSharingPermission.Allowed, [isCurrentProjectAdmin, permissions?.managePublicFileSharingPermission]);

  const canViewTasks = useMemo(() => permissions?.tasksPermission != TasksPermission.LimitedAccess, [permissions]);
  const canManageTasks = useMemo(() => permissions?.tasksPermission === TasksPermission.Manage, [permissions]);

  const canManageWorkflows = useMemo(() => permissions?.workflowsPermission === WorkflowsPermission.Manage, [permissions]);

  const manageables = { canManageProject, canViewBudgetAndExpenses, canManageBudgetAndExpenses, canManageForms, canUploadDocuments, canManageDocuments, canViewTasks, canManageTasks, canManageWorkflows };

  return { ...project, projectId: projectId, query, isCurrentProjectAdmin, canManageDocumentPublicLinkSharing, ...manageables };
};