import { FileIcon } from '@components/Icons/FileIcon';
import { PageSection } from '@components/PageSection/PageSection';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { TaskListItem } from '@modules/tasks/components/TaskListItem';
import { TaskAlt } from '@mui/icons-material';
import { List } from '@mui/material';
import { FormType, useMyTasksQuery, useProjectFormOverviewsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { FormsTable } from './Tables/FormsTable';

export const FormsLimitedAccessView: React.FC = () => {
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();

  const { data: tasks } = useMyTasksQuery({ projectId }, { select: p => p.myTasks });
  const { data: forms, isLoading: isLoadingForms } = useProjectFormOverviewsQuery({ projectId }, { select: p => p.projectFormOverviews });

  const selfServeForms = forms?.filter(p => p.formSettings.formType === FormType.SelfServe) ?? [];
  return (
    <>
      <PageSection
        title={formatMessage({ id: 'Forms to complete' })}
        icon={<TaskAlt />}
        notificationNumber={tasks?.length}
        emptyState={formatMessage({ id: 'There are no forms to complete.' })}
      >
        {(tasks?.length ?? 0 > 0) &&
          <List disablePadding sx={{ borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>
            {tasks?.map(task => (
              <TaskListItem key={task.id} task={task} />
            ))}
          </List>}
      </PageSection>

      {selfServeForms.length > 0 && <PageSection
        title={formatMessage({ id: 'Available self-serve forms' })}
        icon={<FileIcon fileIconType='InternalForm' fontSize='large' />}
      >
        <FormsTable loading={isLoadingForms} forms={forms?.filter(p => p.formSettings.formType === FormType.SelfServe) ?? []} />
      </PageSection>}
    </>
  );
};