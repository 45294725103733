import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useDeleteSubmissionMutation, useProjectFormSubmissionQuery, useProjectFormSubmissionsQuery, useProjectFormsQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SubmissionRow } from './SubmissionsTable';

interface OwnProps {
  submission: Pick<SubmissionRow, 'id' | 'formId'>;
}

export const SubmissionActions: React.FC<OwnProps> = ({ submission }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const submissionId = Number(submission.id);

  const { projectId, isArchived } = useCurrentProject();

  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const { mutate: deleteSubmission, isLoading } = useDeleteSubmissionMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Form submission deleted successfully' }));
      invalidateQuery(useProjectFormSubmissionsQuery, { projectId, formId: submission.formId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectFormSubmissionsQuery, { projectId });
      invalidateQuery(useProjectFormSubmissionQuery, { submissionId });
      setConfirmRemoveDialogOpen(false);
    }
  });

  const onDeleteConfirm = () => {
    deleteSubmission({
      input: { projectId, submissionId }
    });
  };

  return (
    <>
      <ActionMenu open={moreMenuDrawerOpen} onOpen={setMoreMenuDrawerOpen}>
        <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)} disabled={isArchived}>
          <ListItemIcon><Delete /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Delete' })} />
        </MenuItem>
      </ActionMenu>

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete form submission' })}
        content={formatMessage({ id: 'This form submission will be deleted. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isLoading}
      />
    </>
  );
};